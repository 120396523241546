import React from "react";
import {IInstalledExtension, IProductReference} from "../../models/Extension";
import {
    useAddProductReferenceToWorkspaceMutation,
    useGetItemDetailsQuery, useGetWorkspaceProductsQuery,
    useGetWorkspaceRecommendationsQuery
} from "../../store/Api";
import {
    createTheme,
    DefaultButton,
    DetailsList,
    IColumn,
    MessageBar,
    MessageBarType, ProgressIndicator,
    SelectionMode,
    Separator,
    Spinner,
    Text,
    ThemeProvider
} from "@fluentui/react";
import {INormalizedProduct} from "../../models/NormalizedProduct";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";

interface IRecommendedItemsDisplayProps {
    extension: IInstalledExtension;
    workspace: string;
}

interface IRecommendationDisplay {
    addButton?: React.ReactElement;
    item?: INormalizedProduct
    id?: string;
    name: React.ReactElement;
}

const RecommendedItemsDisplay: React.FC<IRecommendedItemsDisplayProps> = (props) => {
    const recommendations = useGetWorkspaceRecommendationsQuery({extensionId: props.extension.id, workspace: props.workspace});
    const existingProducts = useGetWorkspaceProductsQuery(props.workspace);
    const [addToWorkspace] = useAddProductReferenceToWorkspaceMutation();

    if (recommendations.isFetching) return <ProgressIndicator label={`Loading ${props.extension.nickname ?? props.extension.name}`} />;

    const transparentTheme = createTheme({
        palette: {
            white: '#0',
            neutralLighter: '#ddd',
            neutralLight: '#0',
            neutralQuaternaryAlt: '#0',
            themePrimary: '#789c2f',
            themeLighterAlt: '#f9fbf4',
            themeLighter: '#e2e9cb',
            themeLight: '#e2e9cb',
            themeTertiary: '#a8c371',
            themeSecondary: '#e2e9cb',
            themeDarkAlt: '#6b8c2a',
            themeDark: '#5b7623',
            themeDarker: '#43571a',
            neutralLighterAlt: '#faf9f8',
            neutralQuaternary: '#d0d0d0',
            neutralTertiaryAlt: '#c8c6c4',
            neutralTertiary: '#a19f9d',
            neutralSecondary: '#605e5c',
            neutralPrimaryAlt: '#3b3a39',
            neutralPrimary: '#323130',
            neutralDark: '#201f1e',
            black: '#000000',
            red: '#db2828',
            redDark: '#d01919',
        },
    });

    const matchedProducts: IRecommendationDisplay[] = [];
    const matchedIds: IProductReference[] = [];
    if (recommendations.data?.matchedProducts)
        for (const product of recommendations.data.matchedProducts) {
            if (product.productId && existingProducts.data?.findIndex(p => p.productId === product.productId) === -1) {
                const reference: IProductReference = {catalog: 'technology', id: product.productId};
                matchedProducts.push({
                    name: <ProductNameColumn name={product.name} product={product.productId} key={`${product.productId}-name-column`}/>,
                    id: product.productId,
                    addButton: <DefaultButton
                        key={`${product.productId}-name-column-add-button`}
                        style={{color: 'white', backgroundColor: '#9c412f', width: '100%'}}
                        onClick={() => {
                            if (product.productId) {
                                addToWorkspace({
                                    workspace: props.workspace,
                                    product:reference,
                                    req: {}
                                });
                            }
                        }}
                    >Add to Workspace</DefaultButton>
                });
                matchedIds.push(reference);
            }
        }
    if (matchedIds.length > 1)
        matchedProducts.push({
            name: <></>,
            addButton: <DefaultButton
                key={`add-all-button`}
                style={{color: 'white', backgroundColor: '#9c412f', width: '100%'}}
                onClick={() => {
                    for(const reference of matchedIds)
                        addToWorkspace({workspace: props.workspace, product: reference, req: {}})
                }}
            >Add all to Workspace</DefaultButton>
        })

    if (matchedIds.length === 0) return null;

    const columns: IColumn[] = [
        ColumnGenerator('Name', 'name'),
        ColumnGenerator('Actions', 'addButton', 200, 200)
    ];
    return <>
        <MessageBar messageBarType={MessageBarType.info} isMultiline={true} style={{width:'100%'}}>
            <Text>{props.extension.nickname ?? props.extension.name} Recommendations</Text>
            <Separator />
            <ThemeProvider theme={transparentTheme}>
                <DetailsList items={matchedProducts} columns={columns} selectionMode={SelectionMode.none} isHeaderVisible={false} />
            </ThemeProvider>
        </MessageBar>
    </>
}


interface IProductNameColumnProps {
    name?: string;
    catalog?: string;
    product: string;
}
const ProductNameColumn: React.FC<IProductNameColumnProps> = (props) => {
    const reference: IProductReference = {catalog: props.catalog ?? 'technology', id: props.product};
    const details = useGetItemDetailsQuery(reference);

    if (props.name) return <span key={`${props.product}-rec-item-display`}>{props.name}</span>
    if (details.isFetching || details.isLoading) return <Spinner key={`${props.product}-rec-item-display-loading`} label='Loading details' />
    if (details.data?.name) return <span key={`${props.product}-rec-item-display`}>{details.data.name}</span>
    return <span>Unknown Product</span>
}

export default RecommendedItemsDisplay;