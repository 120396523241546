import React, {useEffect, useState} from 'react';
import {IGlobalStorefrontSettings, IStorefrontSettings, PAYMENT_PROCESSOR_KEYS} from '../../models/Storefront';
import {Checkbox, IDropdownOption, MessageBar, MessageBarType, TextField} from '@fluentui/react';
import CompactColorPicker from '../Inputs/CompactColorPicker';
import FontPicker from '../Inputs/FontPicker';
import NumberField from '../Inputs/NumberField';
import MarkupTypeSelection from './MarkupTypeSelection';
import CurrencySelection from '../CurrencySelection/CurrencySelection';
import StorefrontThemeSelection from './StorefrontThemeSelection';
import PaymentProcessorDropdown from './PaymentProcessorDropdown';
import StorefrontOpportunityExtensionForm from "./StorefrontOpportunityExtensionForm";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {useGetOrganizationsQuery, useGetVendorsQuery} from "../../store/Api";

interface IStorefrontSettingsFormProps {
    globalSettings?: IGlobalStorefrontSettings
    settings: IStorefrontSettings;
    onChange?: (settings: IStorefrontSettings) => void;
    errorMessage?: string;
}

const StorefrontSettingsForm: React.FC<IStorefrontSettingsFormProps> = (props) => {
    const [id, setId] = useState<string | undefined>(props.settings.id)
    const [name, setName] = React.useState(props.settings.name);
    const [logo, setLogo] = React.useState(props.settings.logo);
    const [title, setTitle] = React.useState(props.settings.title);
    const [bannerText, setBannerText] = React.useState(props.settings.bannerText);
    const [menuBarColor, setMenuBarColor] = React.useState(props.settings.menuBarColor);
    const [menuBarTextColor, setMenuBarTextColor] = React.useState(props.settings.menuBarTextColor);
    const [backgroundColor, setBackgroundColor] = React.useState(props.settings.backgroundColor);
    const [textColor, setTextColor] = React.useState(props.settings.textColor);
    const [headerFont, setHeaderFont] = React.useState(props.settings.headerFont);
    const [defaultFont, setDefaultFont] = React.useState(props.settings.defaultFont);
    const [termsAndConditions, setTermsAndConditions] = React.useState(props.settings.termsAndConditions);
    const [orderEmail, setOrderEmail] = React.useState(props.settings.orderEmail);
    const [customerEmailText, setCustomerEmailText] = React.useState(props.settings.customerEmailText);
    const [customerEmailSubject, setCustomerEmailSubject] = React.useState(props.settings.customerEmailSubject);
    const [checkoutEnabled, setCheckoutEnabled] = React.useState(props.settings.checkoutEnabled);
    const [checkoutType, setCheckoutType] = React.useState(props.settings.checkoutType);
    const [defaultMarkup, setDefaultMarkup] = React.useState(props.settings.defaultMarkup);
    const [defaultMarkupType, setDefaultMarkupType] = React.useState(props.settings.defaultMarkupType);
    const [subUrl, setSubUrl] = React.useState(props.settings.subUrl);
    const [enabled, setEnabled] = React.useState(props.settings.enabled);
    const [fullCatalogs, setFullCatalogs] = React.useState(props.settings.fullCatalogs);
    const [overrideTermsAndConditions, setOverrideTermsAndConditions] = React.useState(props.settings.overrideTermsAndConditions);
    const [disableNotes, setDisableNotes] = React.useState(props.settings.disableNotes);
    const [notesLabel, setNotesLabel] = useState<string | undefined>(props.settings.notesLabel);
    const [notesInstructions, setNotesInstructions] = useState<string | undefined>(props.settings.notesInstructions);
    const [currency, setCurrency] = React.useState<string | undefined>(props.settings.currency);
    const [theme, setTheme] = React.useState(props.settings.theme || 'CUSTOM');
    const [paymentProcessor, setPaymentProcessor] = React.useState(props.settings.paymentProcessor);
    const [opportunitySettings, setOpportunitySettings] = React.useState(props.settings.opportunitySettings);
    const [opportunityExtension, setOpportunityExtension] = React.useState(props.globalSettings?.opportunityExtension);
    const [storefrontVendors, setStorefrontVendors] = React.useState<string[]>(props.settings.storefrontVendors ?? []);
    const [defaultCatalog, setDefaultCatalog] = useState<string>();
    const [subUrlErrorMessage, setSubUrlErrorMessage] = useState<string | undefined>();
    const vendors = useGetVendorsQuery(defaultCatalog ?? "", {skip: !defaultCatalog})
    const organizations = useGetOrganizationsQuery();
    
    useEffect(() => {
        if(organizations.data) {
            const defaultOrganization = organizations.data.find(o => o.defaultCatalog);
            if(defaultOrganization) {
                setDefaultCatalog(defaultOrganization.defaultCatalog);
            }
        }
    },[organizations.data]);

    
    React.useEffect(() => {
        if(props.settings.id != id) {
            setName(props.settings.name);
            setLogo(props.settings.logo);
            setTitle(props.settings.title);
            setBannerText(props.settings.bannerText);
            setMenuBarColor(props.settings.menuBarColor);
            setMenuBarTextColor(props.settings.menuBarTextColor);
            setBackgroundColor(props.settings.backgroundColor);
            setTextColor(props.settings.textColor);
            setHeaderFont(props.settings.headerFont);
            setDefaultFont(props.settings.defaultFont);
            setTermsAndConditions(props.settings.termsAndConditions);
            setOrderEmail(props.settings.orderEmail);
            setCustomerEmailText(props.settings.customerEmailText);
            setCustomerEmailSubject(props.settings.customerEmailSubject);
            setCheckoutEnabled(props.settings.checkoutEnabled);
            setCheckoutType(props.settings.checkoutType);
            setDefaultMarkup(props.settings.defaultMarkup);
            setDefaultMarkupType(props.settings.defaultMarkupType);
            setSubUrl(props.settings.subUrl);
            setEnabled(props.settings.enabled);
            setFullCatalogs(props.settings.fullCatalogs);
            setOverrideTermsAndConditions(props.settings.overrideTermsAndConditions);
            setCurrency(props.settings?.currency);
            setTheme(props.settings.theme || 'CUSTOM');
            setPaymentProcessor(props.settings.paymentProcessor);
            setOpportunitySettings(props.settings.opportunitySettings);
            setStorefrontVendors(props.settings.storefrontVendors ?? []);
            setDisableNotes(props.settings.disableNotes);
            setNotesLabel(props.settings.notesLabel)
            setNotesInstructions(props.settings.notesInstructions)
        }
    }, [props.settings]);
    
    React.useEffect(() => {
        setOpportunityExtension(props.globalSettings?.opportunityExtension)
    }, [props.globalSettings?.opportunityExtension]);

    React.useEffect(() => {
        if (props.onChange) {
            props.onChange({
                name,
                logo,
                title,
                bannerText,
                menuBarColor,
                menuBarTextColor,
                backgroundColor,
                textColor,
                headerFont,
                defaultFont,
                termsAndConditions,
                orderEmail,
                customerEmailText,
                customerEmailSubject,
                checkoutEnabled,
                checkoutType,
                defaultMarkup,
                defaultMarkupType,
                subUrl,
                enabled,
                fullCatalogs,
                overrideTermsAndConditions,
                currency,
                theme,
                paymentProcessor,
                opportunitySettings,
                storefrontVendors,
                notesLabel,
                disableNotes,
                notesInstructions
            });
        }
    }, [name, logo, title, bannerText, menuBarColor, menuBarTextColor, backgroundColor, textColor, headerFont, defaultFont, termsAndConditions, orderEmail, customerEmailText, customerEmailSubject, checkoutEnabled, checkoutType, defaultMarkup, defaultMarkupType, subUrl, enabled, fullCatalogs, overrideTermsAndConditions, currency, theme, paymentProcessor, opportunitySettings, storefrontVendors, notesLabel, disableNotes, notesInstructions]);

    const onChangeVendors = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            setStorefrontVendors(
                item.selected ? [...storefrontVendors, item.key as string] : storefrontVendors.filter(key => key !== item.key),
            );
        }
    };
    return (
        <div style={{paddingTop: '1em'}}>
            <h3>Settings</h3>
            {props.errorMessage && 
                <MessageBar messageBarType={MessageBarType.error}>{props.errorMessage}</MessageBar>
            }
            <Checkbox label='Enabled' checked={enabled} onChange={(e, c) => setEnabled(c)} />
            <TextField label='Sub URL' value={subUrl} errorMessage={subUrlErrorMessage} onChange={(e, v) => setSubUrl(v ?? '')} />
            <TextField label='Name' value={name} onChange={(e, v) => setName(v)} />
            <TextField label='Title' value={title} onChange={(e, v) => setTitle(v)} />
            <TextField label='Banner Text' value={bannerText} onChange={(e, v) => setBannerText(v)} />
            <h3>Styles</h3>
            <StorefrontThemeSelection theme={theme} onChange={(update) => {
                setTheme(update)}
            } />
            {theme === 'CUSTOM' && <>
                <CompactColorPicker label='Menu Bar Color' color={menuBarColor ?? '#fff'} onChange={setMenuBarColor} placeholder={props.globalSettings?.menuBarColor} />
                <CompactColorPicker label='Menu Bar Text Color' color={menuBarTextColor ?? '#000'} onChange={setMenuBarTextColor} placeholder={props.globalSettings?.menuBarTextColor} />
                <CompactColorPicker label='Background Color' color={backgroundColor ?? '#fff'} onChange={setBackgroundColor} placeholder={props.globalSettings?.backgroundColor} />
                <CompactColorPicker label='Text Color' color={textColor ?? '#000'} onChange={setTextColor} placeholder={props.globalSettings?.textColor} />
            </>}
            <FontPicker label='Header Font' selectedFont={headerFont} onChange={setHeaderFont} placeholder={props.globalSettings?.headerFont} />
            <FontPicker label='Default Font' selectedFont={defaultFont} onChange={setDefaultFont} placeholder={props.globalSettings?.defaultFont} />
            <h3>Order Settings</h3>
            <Checkbox label='Override Terms and Conditions' checked={overrideTermsAndConditions} onChange={(e, c) => setOverrideTermsAndConditions(c)} />
            <Checkbox label='Disable Notes' checked={disableNotes} onChange={(e, c) => setDisableNotes(c)} />
            <TextField label='Notes Label' value={notesLabel} onChange={(e, v) => setNotesLabel(v)} placeholder={props.globalSettings?.notesLabel ?? 'Additional Order Information'}  />
            <TextField multiline label={'Notes Instructions (Markdown Supported)'} value={notesInstructions} onChange={(e, v) => setNotesInstructions(v)} placeholder={props.globalSettings?.notesInstructions} />
            <TextField multiline label={paymentProcessor && paymentProcessor != PAYMENT_PROCESSOR_KEYS.NONE ? 'Terms and Conditions (Text Only)' : 'Terms and Conditions (Markdown Supported)'} value={termsAndConditions} onChange={(e, v) => setTermsAndConditions(v)} />
            <TextField type='email' label='Order Email' value={orderEmail} onChange={(e, v) => setOrderEmail(v)} placeholder={props.globalSettings?.orderEmail} />
            <TextField multiline label='Customer Email Text' value={customerEmailText} onChange={(e, v) => setCustomerEmailText(v)} placeholder={props.globalSettings?.customerEmailText} />
            <TextField label='Customer Email Subject' value={customerEmailSubject} onChange={(e, v) => setCustomerEmailSubject(v)} placeholder={props.globalSettings?.customerEmailSubject} />
            <h3>Pricing Settings</h3>
            <NumberField label='Default Markup' value={defaultMarkup} onChange={setDefaultMarkup} placeholder={props.globalSettings?.defaultMarkup?.toString()} />
            <MarkupTypeSelection value={defaultMarkupType} onChange={setDefaultMarkupType} label='Markup Type' />
            <h3>Payment Settings</h3>
            <CurrencySelection value={currency} onChange={setCurrency} allowInherit />
            <PaymentProcessorDropdown label='Payment Processor' value={paymentProcessor ?? PAYMENT_PROCESSOR_KEYS.NONE} onChange={setPaymentProcessor} />
            <Dropdown
                placeholder={"Select Vendors"}
                multiSelect={true}
                label={"Storefront Vendor Filter"}
                selectedKeys={storefrontVendors}
                options={vendors?.data?.items?.map(vendor => ({ key: vendor, text: vendor })) ?? []}
                onChange={onChangeVendors}
                disabled={vendors.isLoading || vendors.isFetching}
            />
            {opportunityExtension && <>
                <StorefrontOpportunityExtensionForm
                    opportunityCreationEnabled={props.globalSettings?.enableOpportunityCreation}
                    subExtensionFolioCompositeId={opportunityExtension}
                    settings={props.settings}
                    onChange={props.onChange}
                />
            </>
            }
        </div>
    );
}

export default StorefrontSettingsForm;