import React, {useEffect, useState} from 'react';
import { IGlobalStorefrontSettings, PAYMENT_PROCESSOR_KEYS } from '../../models/Storefront';
import {Checkbox, IDropdownOption, TextField} from '@fluentui/react';
import FontPicker from '../Inputs/FontPicker';
import CompactColorPicker from '../Inputs/CompactColorPicker';
import NumberField from '../Inputs/NumberField';
import MarkupTypeSelection from './MarkupTypeSelection';
import CurrencySelection from '../CurrencySelection/CurrencySelection';
import StorefrontThemeSelection from './StorefrontThemeSelection';
import StorefrontDropdown from './StorefrontDropdown';
import PaymentProcessorDropdown from './PaymentProcessorDropdown';
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {useGetOrganizationsQuery, useGetVendorsQuery} from "../../store/Api";
import BooleanDropdown from "./BooleanDropdown";

interface IGlobalStorefrontSettingsFormProps {
    globalSettings?: IGlobalStorefrontSettings;
    onChange: (settings: IGlobalStorefrontSettings) => void;
}

const GlobalStorefrontSettingsForm: React.FC<IGlobalStorefrontSettingsFormProps> = ({globalSettings, onChange}) => {
    const [defaultCatalog, setDefaultCatalog] = useState<string>();
    const organizations = useGetOrganizationsQuery();
    useEffect(() => {
        if(organizations.data) {
            const defaultOrganization = organizations.data.find(o => o.defaultCatalog);
            if(defaultOrganization) {
                setDefaultCatalog(defaultOrganization.defaultCatalog);
            }
        }
    },[organizations.data]);

    const vendors = useGetVendorsQuery(defaultCatalog ?? "", {skip: !defaultCatalog})
    const [name, setName] = React.useState(globalSettings?.name);
    const [logo, setLogo] = React.useState(globalSettings?.logo);
    const [title, setTitle] = React.useState(globalSettings?.title);
    const [bannerText, setBannerText] = React.useState(globalSettings?.bannerText);
    const [menuBarColor, setMenuBarColor] = React.useState(globalSettings?.menuBarColor);
    const [menuBarTextColor, setMenuBarTextColor] = React.useState(globalSettings?.menuBarTextColor);
    const [backgroundColor, setBackgroundColor] = React.useState(globalSettings?.backgroundColor);
    const [textColor, setTextColor] = React.useState(globalSettings?.textColor);
    const [headerFont, setHeaderFont] = React.useState(globalSettings?.headerFont);
    const [defaultFont, setDefaultFont] = React.useState(globalSettings?.defaultFont);
    const [termsAndConditions, setTermsAndConditions] = React.useState(globalSettings?.termsAndConditions);
    const [orderEmail, setOrderEmail] = React.useState(globalSettings?.orderEmail);
    const [orderFailureEmail, setOrderFailureEmail] = React.useState(globalSettings?.orderEmail);
    const [customerEmailText, setCustomerEmailText] = React.useState(globalSettings?.customerEmailText);
    const [customerEmailSubject, setCustomerEmailSubject] = React.useState(globalSettings?.customerEmailSubject);
    const [storefrontsEnabled, setStorefrontsEnabled] = React.useState(globalSettings?.storefrontsEnabled);
    const [checkoutEnabled, setCheckoutEnabled] = React.useState(globalSettings?.checkoutEnabled);
    const [checkoutType, setCheckoutType] = React.useState(globalSettings?.checkoutType);
    const [defaultMarkup, setDefaultMarkup] = React.useState(globalSettings?.defaultMarkup);
    const [defaultMarkupType, setDefaultMarkupType] = React.useState(globalSettings?.defaultMarkupType);
    const [currency, setCurrency] = React.useState<string>(globalSettings?.currency ?? 'USD');
    const [theme, setTheme] = React.useState(globalSettings?.theme);
    const [defaultStorefrontSubUrl, setDefaultStorefrontSubUrl] = React.useState(globalSettings?.defaultStorefrontSubUrl);
    const [paymentProcessor, setPaymentProcessor] = React.useState(globalSettings?.paymentProcessor);
    const [collectTax, setCollectTax] = React.useState(globalSettings?.collectTax);
    const [enableOpportunityCreation, setEnableOpportunityCreation] = React.useState(globalSettings?.enableOpportunityCreation)
    const [opportunityExtension, setOpportunityExtension] = React.useState(globalSettings?.opportunityExtension);
    const [storefrontVendors, setStorefrontVendors] = React.useState<string[]>(globalSettings?.storefrontVendors ?? []);
    const [requireNotes, setRequireNotes] = useState<boolean | undefined>(globalSettings?.requireNotes);
    const [notesLabel, setNotesLabel] = useState<string | undefined>(globalSettings?.notesLabel);
    const [notesInstructions, setNotesInstructions] = useState<string | undefined>(globalSettings?.notesInstructions);
    React.useEffect(() => {
        setName(globalSettings?.name);
        setLogo(globalSettings?.logo);
        setTitle(globalSettings?.title);
        setBannerText(globalSettings?.bannerText);
        setMenuBarColor(globalSettings?.menuBarColor);
        setMenuBarTextColor(globalSettings?.menuBarTextColor);
        setBackgroundColor(globalSettings?.backgroundColor);
        setTextColor(globalSettings?.textColor);
        setHeaderFont(globalSettings?.headerFont);
        setDefaultFont(globalSettings?.defaultFont);
        setTermsAndConditions(globalSettings?.termsAndConditions);
        setOrderEmail(globalSettings?.orderEmail);
        setCustomerEmailText(globalSettings?.customerEmailText);
        setCustomerEmailSubject(globalSettings?.customerEmailSubject);
        setStorefrontsEnabled(globalSettings?.storefrontsEnabled);
        setCheckoutEnabled(globalSettings?.checkoutEnabled);
        setCheckoutType(globalSettings?.checkoutType);
        setDefaultMarkup(globalSettings?.defaultMarkup);
        setDefaultMarkupType(globalSettings?.defaultMarkupType);
        setCurrency(globalSettings?.currency ?? 'USD');
        setTheme(globalSettings?.theme ?? 'CUSTOM');
        setDefaultStorefrontSubUrl(globalSettings?.defaultStorefrontSubUrl);
        setPaymentProcessor(globalSettings?.paymentProcessor);
        setCollectTax(globalSettings?.collectTax);
        setOpportunityExtension(globalSettings?.opportunityExtension);
        setEnableOpportunityCreation(globalSettings?.enableOpportunityCreation);
        setOrderFailureEmail(globalSettings?.orderFailureEmail);
        setStorefrontVendors(globalSettings?.storefrontVendors ?? []);
        setNotesLabel(globalSettings?.notesLabel);
        setRequireNotes(globalSettings?.requireNotes)
        setNotesInstructions(globalSettings?.notesInstructions);
    }, [globalSettings]);

    React.useEffect(() => {
        onChange({
            name,
            logo,
            title,
            bannerText,
            menuBarColor,
            menuBarTextColor,
            backgroundColor,
            textColor,
            headerFont,
            defaultFont,
            termsAndConditions,
            orderEmail,
            customerEmailText,
            customerEmailSubject,
            storefrontsEnabled,
            checkoutEnabled,
            checkoutType,
            defaultMarkup,
            defaultMarkupType,
            currency,
            theme,
            defaultStorefrontSubUrl,
            paymentProcessor,
            collectTax,
            opportunityExtension,
            enableOpportunityCreation,
            orderFailureEmail,
            storefrontVendors,
            requireNotes,
            notesLabel,
            notesInstructions
        });
    }, [name, logo, title, bannerText, menuBarColor, menuBarTextColor, backgroundColor, textColor, headerFont, defaultFont, termsAndConditions, orderEmail, customerEmailText, customerEmailSubject, storefrontsEnabled, checkoutEnabled, checkoutType, defaultMarkup, defaultMarkupType, theme, defaultStorefrontSubUrl, paymentProcessor, collectTax, storefrontVendors, requireNotes, notesLabel, notesInstructions]);
    const onChangeVendors = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            setStorefrontVendors(
                item.selected ? [...storefrontVendors, item.key as string] : storefrontVendors.filter(key => key !== item.key),
            );
        }
    };
    return <div style={{ width: '100%', minWidth: '300px'}}>
        <h2>Global Settings</h2>
        <StorefrontDropdown label='Default Storefront Sub URL' selected={defaultStorefrontSubUrl} onChange={setDefaultStorefrontSubUrl} defaultLabel={'NOT SET'} />
        <TextField label='Name' value={name} onChange={(e, v) => setName(v)} />
        <TextField label='Title' value={title} onChange={(e, v) => setTitle(v)} />
        <TextField label='Banner Text' value={bannerText} onChange={(e, v) => setBannerText(v)} />
        <h3>Styles</h3>
        <StorefrontThemeSelection theme={theme} onChange={setTheme} />
        {theme === 'CUSTOM' && <>
            <CompactColorPicker label='Menu Bar Color' color={menuBarColor ?? '#fff'} onChange={setMenuBarColor} />
            <CompactColorPicker label='Menu Bar Text Color' color={menuBarTextColor ?? '#000'} onChange={setMenuBarTextColor} />
            <CompactColorPicker label='Background Color' color={backgroundColor ?? '#fff'} onChange={setBackgroundColor} />
            <CompactColorPicker label='Text Color' color={textColor ?? '#000'} onChange={setTextColor} />
        </>}
        <FontPicker label='Header Font' selectedFont={headerFont} onChange={setHeaderFont} />
        <FontPicker label='Default Font' selectedFont={defaultFont} onChange={setDefaultFont} />
        <h3>Order Settings</h3>
        <BooleanDropdown value={requireNotes ?? false} onChange={setRequireNotes} label={'Require Notes'} />
        <TextField label='Notes Label' value={notesLabel} onChange={(e, v) => setNotesLabel(v)} placeholder={'Additional Order Information'} />
        <TextField multiline label={'Notes Instructions (Markdown Supported)'} value={notesInstructions} onChange={(e, v) => setNotesInstructions(v)} />

        <TextField multiline label={paymentProcessor && paymentProcessor != PAYMENT_PROCESSOR_KEYS.NONE ? 'Terms and Conditions (Plain Text Only)' : 'Terms and Conditions (Markdown Supported)'} value={termsAndConditions} onChange={(e, v) => setTermsAndConditions(v)} />
        <TextField type='email' label='Order Email' value={orderEmail} onChange={(e, v) => setOrderEmail(v)} />
        <TextField multiline label='Customer Email Text' value={customerEmailText} onChange={(e, v) => setCustomerEmailText(v)} />
        <TextField label='Customer Email Subject' value={customerEmailSubject} onChange={(e, v) => setCustomerEmailSubject(v)} />
        <h3>Markup Settings</h3>
        <NumberField label='Default Markup' value={defaultMarkup} onChange={setDefaultMarkup} />
        <MarkupTypeSelection value={defaultMarkupType} onChange={setDefaultMarkupType} label='Default Markup Type' />
        <h3>Payment Settings</h3>
        <CurrencySelection value={currency} onChange={setCurrency} />
        <PaymentProcessorDropdown value={paymentProcessor ?? PAYMENT_PROCESSOR_KEYS.NONE} onChange={setPaymentProcessor} />
        <BooleanDropdown value={collectTax ?? false} onChange={setCollectTax} label={"Collect Tax"} />
        <h3>Other Settings</h3>
        <TextField type='email' label='Order Failure Email' value={orderFailureEmail} onChange={(e, v) => setOrderFailureEmail(v)} />
        <Dropdown
            placeholder={"Select Vendors"}
            multiSelect={true}
            label={"Storefront Vendor Filter"}
            selectedKeys={storefrontVendors}
            options={vendors?.data?.items?.map(vendor => ({ key: vendor, text: vendor })) ?? []}
            onChange={onChangeVendors}
            disabled={vendors.isLoading || vendors.isFetching}
        />
    </div>
}

export default GlobalStorefrontSettingsForm;