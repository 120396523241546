import React, {FocusEventHandler, useEffect} from 'react';
import {MaskedTextField, TextField} from '@fluentui/react';

interface IDataFieldProps {
    required?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    label?: string;
    value?: string;
    type?: string;
    mask?: string;
    maskFormat?: string;
    maskChar?: string;
    onChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | undefined>;
    hideIfEmpty?: boolean;
    placeholder?: string;
}
const DataField: React.FC<IDataFieldProps> = (props) => {
    const [maskFormat, setMaskFormat] = React.useState<{ [key: string]: RegExp }>();
    useEffect(() => {
        console.log('mask:', props.mask);
        console.log('mask format:', props.maskFormat)
        console.log('mask char:', props.maskChar)
        if(props.maskFormat) {
            setMaskFormat(  {[props.maskChar ?? '*']: new RegExp(props.maskFormat)});
        }
    }, [props.maskFormat]);

    const getErrorMessage = (value: string): string => {
        return value.length < 1 && props.required ? 'Field is required' : '';
    };
    if(props.hideIfEmpty && (!props.value || props.value == '')){
        return <></>
    }
    if(props.readOnly || props.disabled){
        return( 
            <div className={'ac-details-field'}>
                {props.label && <h4>{props.label}</h4>}
                {props.value ?? props.placeholder}
            </div>
        )
    }
    if(props.mask){
        return (
            <MaskedTextField
                required={props.required}
                type={props.type}
                mask={props.mask}
                maskFormat={maskFormat}
                label={props.label}
                value={props.value ?? ""}
                onChange={props.onChange}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
                styles={{root:{paddingBottom: '2px'}}}
                onGetErrorMessage={getErrorMessage}
            />
        )
    }
    return (
        <TextField
            type={props.type}
            required={props.required}
            label={props.label}
            value={props.value ?? ""}
            onChange={props.onChange}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            styles={{root:{paddingBottom: '2px'}}}
            onGetErrorMessage={getErrorMessage}
        />
    );
}

export default DataField;