import React from 'react';
import {useGetWorkspaceProductsQuery, useGetWorkspaceQuery} from "../../store/Api";
import {Stack, Text} from "@fluentui/react";
import { calculateCost, calculatePrice } from '../../logic/Product';
import CurrencyFormat from "react-currency-format";

interface IWorkspaceTotalsDisplayProps {
    workspace: string;
}

const WorkspaceTotalsDisplay: React.FC<IWorkspaceTotalsDisplayProps> = (props) => {
    const products = useGetWorkspaceProductsQuery(props.workspace);
    const workspace = useGetWorkspaceQuery(props.workspace);
    if (products.data == null || products.data.length === 0)
        return <></>

    const formatCurrency = (amount: number) => {
        if (amount == null) return null;
        return <CurrencyFormat
            value={amount}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={2}
            fixedDecimalScale={true}
        />;
    }

    let cost = 0;
    let price = 0;
    for(const product of products.data) {
        if (product.normalized == null) continue;
        const quantity = product.details.quantity;
        const pCost = calculateCost(product.normalized, product.details.source, product.details.discountedCost, product.details.overrideCost);
        const pPrice = +calculatePrice(product.normalized, product.details, workspace.data?.defaultMarkup, workspace.data?.defaultListDiscount);
        if (pCost != undefined) cost += (quantity ?? 1) * pCost;
        if (pPrice != undefined) price += (quantity ?? 1) * pPrice;
    }
    return <Stack horizontal tokens={{childrenGap: '1em'}}>
        <Text variant='mediumPlus'>Total Cost: {formatCurrency(cost)}</Text>
        <Text variant='mediumPlus'>Total Price: {formatCurrency(price)}</Text>
    </Stack>
}

export default WorkspaceTotalsDisplay;