import React, {useCallback, useEffect, useState} from 'react';
import {IAdditionalData} from '../../models/Extension';
import {debounce} from "lodash";
import {
	useLazyGetExportExtensionOptionFriendlyNameQuery,
	useLazyGetExportExtensionOptionsQuery
} from "../../store/Api";
import AutocompleteSearchBox, {ISuggestionItem} from "../Common/AutocompleteSearchBox";

interface IExtensionFieldSearchProps {
	field: IAdditionalData;
	isDisabled?: boolean;
	extensionId: string;
	initialValue?: string;
	onChange: (value: string) => void;
}

const ExtensionFieldSearch: React.FC<IExtensionFieldSearchProps> = (props) => {
	const [searchText, setSearchText] = useState<string>();
	const [options, setOptions] = useState<{key: string, text: string, value: string}[]>([]);
	const [value, setValue] = useState<string | undefined>()
	const [valueLabel, setValueLabel] = useState<string | undefined>()
	const [defaultValueAssigned, setDefaultValueAssigned] = useState<boolean>();
	const [getExtensionOptions] = useLazyGetExportExtensionOptionsQuery();
	const [getOptionFriendlyName] = useLazyGetExportExtensionOptionFriendlyNameQuery();
	const [inProgress, setProgress] = React.useState(false);
	const [dynamicSuggestions, setDynamicSuggestions] = React.useState<ISuggestionItem[]>();
	const debouncedSetSearchedKeywords = useCallback(
		debounce(async (val:string | undefined) => {
			if(val &&  val.length > 0) {
				await load(val)
			}
		}, 200, {leading: false}),
		[]
	);
	const queryThreshold = 2;
	useEffect(() => {
		if(props.initialValue && !defaultValueAssigned){
			setValue(props.initialValue)
			if(props.field.field && props.extensionId) {
				getOptionFriendlyName({
					extensionId: props.extensionId,
					field: props.field.field,
					search: props.initialValue
				}).unwrap().then((result) => {setValueLabel(result)})
			} 
			else {
				setValueLabel(props.initialValue)
			}
			setDefaultValueAssigned(true)
			if(!props.field.isFilterSearch) {
				props.onChange(props.initialValue)
			}
		}
	}, [props.initialValue])
	
	const load = async (value: string) => {
		if (value && value != '' && props.extensionId && props.extensionId != '' ) {
			getExtensionOptions({extensionId: props.extensionId, field: props.field.field, search: value, additionalData: undefined})
				.unwrap().then((result) => {
					if (result) {
						const options = result.options.map(
							(val: any) => new ExtensionFieldSearchOption(val.value, val.display, val.value)
						)
						setDynamicSuggestions(options)
					}
					setProgress(false)
				}
			)
		}
	}

	const onChangeDynamic = async (newText?: string) => {
		if (!newText || newText.trim().length < queryThreshold) {
			setDynamicSuggestions(undefined);
		} else {
			setProgress(true);
			await load(newText)
		}
	};

	const onSuggestionClicked = (suggestion: string | ISuggestionItem) => {
		if(typeof  suggestion === "string") {
			return;
		}
		setValue(suggestion.getKey());
		props.onChange(suggestion.getKey())
		setProgress(false)
	};

	const getPlaceholder = () => {
		if(value){
			return `Selected Value: ${valueLabel ?? value}`
		}
		return `Search ${props.field.description}`
	}
	
	return (
		<React.Fragment>
			<AutocompleteSearchBox
				placeholder={getPlaceholder()}
				onChange={async (_, newValue) => await onChangeDynamic(newValue)}
				onSuggestionClicked={onSuggestionClicked}
				value={searchText}
				suggestions={dynamicSuggestions}
				inProgress={inProgress}
				debounceTime={500}
				onClear={(ev) => {
					setValue(undefined)
					props.onChange('')
				}}
			/>
			{!props.field.optional && !value && <p className={'field-error-message'}>Field is required</p>}
		</React.Fragment>
	);
}

class ExtensionFieldSearchOption implements ISuggestionItem {
	constructor(
		private key: string,
		private text: string,
		private value: string,
	) {}
	getSearchText: () => string = () => {
		return this.text;
	};
	getSuggestionItem(query?: string) {
		return (
			<div key={this.key} className="suggestionItem">
				{this.text}
			</div>
		);
	}
	getKey: () => string = () => {
		return this.key;
	};
}

export default ExtensionFieldSearch;