import React from 'react';
import {Checkbox} from '@fluentui/react';

interface IToggleFieldProps {
    readonly?: boolean;
    disabled?: boolean;
    label?: string;
    checked?: boolean;
    onChange?: (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => void;
}

const ToggleField: React.FC<IToggleFieldProps> = (props) => {
    if(props.readonly || props.disabled){
        return( 
            <div className={'ac-details-field'}>
                <h4>{props.label}</h4>
                <Checkbox
                    styles={{text: {color: 'black', display: 'inline-flex'}}}
                    disabled={true}
                    checked={props.checked}
                />
            </div>
        )
    }

    return (
        <Checkbox
            disabled={false}
            label={props.label}
            checked={props.checked}
            onChange={props.onChange}
        />
    );
}

export default ToggleField;