import React from 'react';
import { IGlobalStorefrontSettings, IStorefrontSettings, IStorefrontTheme } from '../../models/Storefront';
import { TextField, Image, Spinner } from '@fluentui/react';
import { useGetStorefrontThemesQuery } from '../../store/Api';

interface IStorefrontPreviewProps {
    globalSettings?: IGlobalStorefrontSettings;
    storefrontSettings?: IStorefrontSettings;
}

interface IStorefrontStyling {
    bgColor: string;
    textColor: string;
    mbColor: string;
    mbTextColor: string;
}

const calculateStyling = (globalSettings?: IGlobalStorefrontSettings, storefrontSettings?: IStorefrontSettings, sfTheme?: IStorefrontTheme, gTheme?: IStorefrontTheme): IStorefrontStyling => {
    const gSettings: IStorefrontStyling = globalSettings?.theme != null && globalSettings.theme === 'CUSTOM' ? {
        bgColor: globalSettings.backgroundColor ?? '#fff',
        textColor: globalSettings.textColor ?? '#000',
        mbColor: globalSettings.menuBarColor ?? '#fff',
        mbTextColor: globalSettings.menuBarTextColor ?? '#000',
    } : {
        bgColor: gTheme?.backgroundColor ?? globalSettings?.backgroundColor ?? '#fff',
        textColor: gTheme?.textColor ?? globalSettings?.textColor ?? '#000',
        mbColor: gTheme?.menuBarColor ?? globalSettings?.menuBarColor ?? '#fff',
        mbTextColor: gTheme?.menuBarTextColor ?? globalSettings?.menuBarTextColor ?? '#000',
    }
    if (storefrontSettings?.theme == null || storefrontSettings.theme === 'CUSTOM') {
        return {
            bgColor: storefrontSettings?.backgroundColor ?? gSettings.bgColor,
            textColor: storefrontSettings?.textColor ?? gSettings.textColor,
            mbColor: storefrontSettings?.menuBarColor ?? gSettings.mbColor,
            mbTextColor: storefrontSettings?.menuBarTextColor ?? gSettings.mbTextColor,
        }
    }
    return {
        bgColor: sfTheme?.backgroundColor ?? gSettings.bgColor,
        textColor: sfTheme?.textColor ?? gSettings.textColor,
        mbColor: sfTheme?.menuBarColor ?? gSettings.mbColor,
        mbTextColor: sfTheme?.menuBarTextColor ?? gSettings.mbTextColor,
    }
}

const StorefrontPreview: React.FC<IStorefrontPreviewProps> = ({ globalSettings, storefrontSettings }) => {
    const themes = useGetStorefrontThemesQuery();
    if (themes.isLoading) return <Spinner label='Loading Preview' />
    const styling = calculateStyling(
        globalSettings,
        storefrontSettings,
        storefrontSettings?.theme ? themes.data?.[storefrontSettings.theme] : undefined,
        globalSettings?.theme ? themes.data?.[globalSettings.theme] : undefined
    );
    console.log(['STYLING', styling]);

    const defaultFont = storefrontSettings?.defaultFont ?? globalSettings?.defaultFont;
    const headerFont = storefrontSettings?.headerFont ?? globalSettings?.headerFont;
    const name = storefrontSettings?.name ?? globalSettings?.name ?? 'Storefront Name';
    const title = storefrontSettings?.title ?? globalSettings?.title ?? 'Storefront Title';
    const bannerText = storefrontSettings?.bannerText ?? globalSettings?.bannerText;

    return (
        <div style={{ width: '500px' }}>
            <h2>Storefront Preview</h2>
            <div style={{
                backgroundColor: styling.bgColor,
                color: styling.textColor,
                fontFamily: defaultFont,
                borderRadius: '5px',
                border: '1px solid #ccc',
                boxShadow: '2px 2px 5px #ccc',
                width: '500px',
                margin: 'auto'
            }}>
                <nav
                    style={{
                        backgroundColor: styling.mbColor,
                        color: styling.mbTextColor,
                        padding: '1em',
                        borderRadius: '5px 5px 0 0',
                        marginBottom: '1em',
                        width: '100%',
                        boxShadow: '0px 2px 5px #ccc',
                    }}
                >
                    <span style={{ fontFamily: headerFont }}>{name}</span>
                </nav>
                <div style={{ padding: '1em' }}>
                    {globalSettings?.title && <h3 style={{textAlign: 'center', fontFamily: headerFont}}>{title}</h3>}
                    {globalSettings?.bannerText && <div style={{ color: '#000', backgroundColor: '#f0f0f0', padding: '1em', borderRadius: '5px', marginBottom: '1em' }}>{bannerText}</div>}
                    <TextField placeholder="Search" />
                    <div style={{ marginTop: '1em' }}>
                        <StorefrontExampleCard />
                        <StorefrontExampleCard />
                        <StorefrontExampleCard />
                        <StorefrontExampleCard />
                        <StorefrontExampleCard />
                        <StorefrontExampleCard />
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </div>
    );
};

const StorefrontExampleCard = () => {
    return (
        <div style={{ width: '31%', backgroundColor: '#fff', borderRadius: '2px', border: '1px solid #ccc', float: 'left', margin: '5px', padding: '5px', color: '#000' }}>
            <Image src='https://resources.adaptivecatalog.com/images/no-image.jpg' style={{margin: '0 auto', width: '100%'}} />
            Example Product
        </div>
    )
}

export default StorefrontPreview;