import { Dropdown, DropdownMenuItemType, IDropdownOption, ISelectableOption, TextField } from '@fluentui/react';
import React from 'react';
import { useGetCompaniesQuery } from '../../store/Api';

interface ICompanyDropdownProps {
    selected?: string;
    onChange: (value: IDropdownOption | undefined) => void;
}

const CompanyDropdown: React.FC<ICompanyDropdownProps> = (props) => {
    const [query, setQuery] = React.useState('')
    const [tempQuery, setTempQuery] = React.useState('')
    const companies = useGetCompaniesQuery(query)

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (tempQuery !== query) {
                setQuery(tempQuery)
            }
        }, 500)
        return () => clearTimeout(timeout)
    }, [tempQuery, query])

    const options: IDropdownOption[]  = [
        { key: 'SEARCH', text: 'Search', itemType: DropdownMenuItemType.Header },
        ...companies.data?.map((company) => ({ key: company.id ?? '', text: company.companyName ?? '' })) ?? [],
    ]

    const onRenderOption = (option: ISelectableOption<IDropdownOption> | undefined): JSX.Element => {
        if (option?.itemType === DropdownMenuItemType.Header) {
            return (
                <div style={{padding: '2px 0 2px 0', width: '100%'}}>
                    <TextField
                        value={tempQuery}
                        style={{ width: '100%' }}
                        placeholder='Search...'
                        onChange={(e, newValue) => setTempQuery(newValue ?? '')}
                        />
                </div>
            );
        }
        return (
          <div>
            <span>{option?.text}</span>
          </div>
        );
      };

    return (
        <div>
            <Dropdown
                label='Company'
                selectedKey={props.selected}
                onChange={(e, option) => {
                    console.log(['COMPANY SELECTED', option]);
                    props.onChange(option);
                }}
                disabled={companies.isLoading}
                options={options}
                onRenderOption={onRenderOption}
                />
        </div>
    );
}

export default CompanyDropdown;