import React, {useEffect} from 'react';
import {CommandBar, ICommandBarItemProps, IStyle, Layer} from "@fluentui/react";
import LogRocket from "logrocket";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";

const SessionKeyNavBar: React.FC = () => {
    const selectedOrganization = useSelector((state: RootState) => state.settings.selectedOrganization);
    const history = useHistory();

    useEffect(() => {
        if (selectedOrganization) {
            LogRocket.identify(selectedOrganization.id + ' Punchout', {});
        }
    }, [selectedOrganization]);


    const navMenuStyle: IStyle = {
        padding: 0,
        height: '48px',
        borderBottom: '1px solid #ccc',
        boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
    }

    const buttonStyle: IStyle = {
    }

    const imageStyle: React.CSSProperties = {
        padding: '3px',
        height: '35px'
    }

    const commands: ICommandBarItemProps[] = [
        {
            key: 'Logo',
            text: 'Home',
            iconOnly: true,
            onRenderIcon: () => <img
                src="mainLogo.png"
                alt='Adaptive Catalog logo'
                style={imageStyle}
            />,
        },
    ]

    const rightCommands: ICommandBarItemProps[] = [
        {
            key: 'Search',
            text: 'Search',
            buttonStyles: {
                root: {
                    ...buttonStyle,
                    backgroundColor: (window.location.pathname.toLowerCase().indexOf('/search') !== -1) ? '#ddd' : 'white'
                }
            },
            onClick: () => {
                history.push('/punchout/search');
            },
        },
        {
            key: 'Workspace',
            text: 'Workspace',
            buttonStyles: {
                root: {
                    ...buttonStyle,
                    backgroundColor: (window.location.pathname.toLowerCase().indexOf('/workspace') !== -1) ? '#ddd' : 'white'
                }
            },
            onClick: () => {
                history.push('/punchout/workspace/last');
            },
        },
    ]

    return (
        <>
            <header>
                <Layer>
                    <div>
                        <CommandBar
                            styles={{
                                root: navMenuStyle,
                            }}
                            items={commands}
                            farItems={rightCommands}
                        />
                    </div>
                </Layer>
            </header>
        </>
    );
}

export default SessionKeyNavBar;