import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/rootReducer';
import {
    CommandBar,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    ICommandBarItemProps,
    IContextualMenuItem,
    TextField,
    ContextualMenuItemType
} from '@fluentui/react';
import WorkspaceSearchModal from '../Workspace/WorkspaceSearchModal'
import {IWorkspace, IWorkspaceItem} from '../../models/Workspace';
import {WorkspaceDAL} from "../../dal/WorkspaceDAL";
import CustomizationModal from "../ColumnCustomization/CustomizationModal";
import {
    useAddWorkspaceMutation,
    useGetInstalledExtensionsQuery,
    useGetRecentWorkspacesQuery,
    useRemoveProductFromWorkspaceMutation,
    useRemoveWorkspaceMutation,
    usePutWorkspaceMutation,
    useImportWorkspaceProductsFromTemplateMutation,
    useGetTemplateWorkspacesQuery,
    useAddWorkspaceWithTemplateDefaultsMutation,
    useRunDocumentExtensionMutation, 
    useGetOrganizationsQuery, 
    useLazyGetWorkspaceQuery
} from "../../store/Api";
import {IInstalledExtension} from "../../models/Extension";
import {setPunchoutWorkspace} from '../../store/settingsSlice';
import WorkspaceDeleteModal from "./WorkspaceDeleteModal";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import WorkspaceProcurementModal from "./WorkspaceProcurementModal";
import {useHistory} from "react-router-dom";
import CAN, { STOREFRONT_ADMIN } from '../../permissions/ability';
import WorkspaceContactModal from "./WorkspaceContactModal";

interface IWorkspaceControlsProps {
    selectedWorkspace: string;
    selectedContact?: string;
    selectedItems: IWorkspaceItem[];
    onExtensionSelection: (extension: IInstalledExtension) => void;
    onCustomerRequestSelection: () => void;
    onImportSelection: () => void;
    onRefresh?: () => void;
    onAdhocClick: () => void;
    onRefreshVendors?: () => void;
    onStorefrontSelection: () => void;
    refreshingVendors?: boolean;
}
const WorkspaceControls: React.FC<IWorkspaceControlsProps> = props => {
    const getWorkspaceTemplates = useGetTemplateWorkspacesQuery();
    const getWorkspaces = useGetRecentWorkspacesQuery();
    const [importProducts] = useImportWorkspaceProductsFromTemplateMutation()
    const [removeWorkspace] = useRemoveWorkspaceMutation();
    const [addWorkspace] = useAddWorkspaceMutation();
    const [addWorkspaceFromTemplate] = useAddWorkspaceWithTemplateDefaultsMutation()
    const [updateWorkspace] = usePutWorkspaceMutation();
    const [workspaceSearchModalOpen, setWorkspaceSearchModalOpen]= useState<boolean>(false);
    const [contactModalOpen, setContactModalOpen] = useState<boolean>(false);
    const [procurementModalOpen, setProcurementModalOpen] = useState<boolean>(false);
    const selectedExtension = useSelector((state: RootState) => state.settings.targetExtension);
    const punchoutWorkspaceId = useSelector((state: RootState) => state.settings.punchoutWorkspace);
    const [selectedWorkspace, setSelectedWorkspace] = useState<string>();
    const [workspaceData, setWorkspaceData] = useState<IWorkspace>();
    const [getWorkspace] = useLazyGetWorkspaceQuery()
    const [newWorkspaceName, setNewWorkspaceName] = React.useState('');
    const [createWorkspaceModalOpen, setCreateWorkspaceModalOpen] = React.useState(false);
    const [deleteWorkspaceModalOpen, setDeleteWorkspaceModalOpen] = React.useState(false);
    const [recentWorkspaces, setRecentWorkspaces] = React.useState<IWorkspace[] | undefined>([])
    const [workspaceToImport, setWorkspaceToImport] = React.useState<string | number | undefined>('')
    const [currentSelectedItems, setCurrentSelectedItems] = React.useState(props.selectedItems);
    const [removeProduct] = useRemoveProductFromWorkspaceMutation();
    const getInstalled = useGetInstalledExtensionsQuery();
    const [runDocumentExtension] = useRunDocumentExtensionMutation();
    const [documentGenerating, setDocumentGenerating] = useState<boolean>();
    const auth = useSelector((state: RootState) => state.auth);
    const settings = useSelector((state: RootState) => state.settings);
    const [submitUrl, setSubmitUrl] = React.useState<string | undefined>(undefined)
    const [submitData, setSubmitData] = React.useState<string | undefined>(undefined)
    const formRef = useRef<null | HTMLFormElement>(null);
    const selectedOrganization = useSelector((state: RootState) => state.settings.selectedOrganization);
    const getOrganizations = useGetOrganizationsQuery();
    const [selectedContact, setSelectedContact] = useState<string| undefined>();
    const [editColumnsModalOpen, setEditColumnsModalOpen] = React.useState(false);
    const [loadingWorkspace, setLoadingWorkspace] = useState<boolean>();
    const history = useHistory()
    const canStorefront = CAN(STOREFRONT_ADMIN);
    useEffect(() => {
        if (props.selectedItems) {
            setCurrentSelectedItems(props.selectedItems)
        }
    }, [props.selectedItems])
    
    useEffect(() => {
        if(!getWorkspaces.isFetching) {
            setRecentWorkspaces(getWorkspaces.data)
        }
    }, [getWorkspaces.data])
    useEffect(() => {
        setLoadingWorkspace(true)
        setSelectedWorkspace(props.selectedWorkspace)
        //Make this not lazy, otherwise we will miss attached company changes
        getWorkspace(props.selectedWorkspace).unwrap().then((ws) => {
            setWorkspaceData(ws)
            setLoadingWorkspace(false)
        })
    }, [props.selectedWorkspace])

    useEffect(() => {
        
        setSelectedContact(props.selectedContact)

    }, [props.selectedContact])
    
    const toggleTemplate = (isTemplate: boolean) => {
        if(selectedWorkspace) {
            updateWorkspace({isTemplate: isTemplate, id: selectedWorkspace})
        }
    }
    const removeClick = () => {
        if (selectedWorkspace) {
            for(const item of currentSelectedItems) {
                removeProduct({workspaceId:selectedWorkspace, wsItemId:item.id});
            }
            setCurrentSelectedItems([]);
        }
    }
    const openCompanyModal = () => {
        setContactModalOpen(true);
    }
    const openDocument = () => {
        if(workspaceData?.documentUrl)
            window.open(workspaceData.documentUrl, '_blank')
    }
    const openProcurement = () => {
        setProcurementModalOpen(true);
    }
    const pluralized = (count: number, noun: string) => {
        return count >= 2 || count === 0 ? `${noun}s` : noun;
    }
    const updateContact = (companyName: string, contactId: string, companyId: string) => {
        if(selectedWorkspace) {
            setSelectedContact(contactId)
            updateWorkspace({companyName: companyName, contactId: contactId, companyId: companyId, id: selectedWorkspace, documentIsCurrent: false})
        }
    }

    const submitSession = async () => {
        if (selectedWorkspace) {
            const dal = new WorkspaceDAL(auth);
            if(settings.punchoutMode == "json") {
                const results = await dal.postSubmitJsonWorkspaceSession(selectedWorkspace);
                if(results){
                    cleanupPunchout();
                    window.open(results, "_self")
                }
            } else {
                const results = await dal.postSubmitWorkspaceSession(selectedWorkspace);
                if(results && formRef?.current) {
                    setSubmitUrl(results.postUrl);
                    setSubmitData(results.data);
                    cleanupPunchout();
                    formRef?.current.submit();
                }
            }
        }
    }

    const cleanupPunchout = () => {
        if (punchoutWorkspaceId != undefined) {
            removeWorkspace(punchoutWorkspaceId).then();
        }
        const data = recentWorkspaces;
        if (data === undefined) return;
        setSelectedWorkspace(data[0].id);
        setPunchoutWorkspace(undefined);
    }
    const ws: IContextualMenuItem[] = recentWorkspaces?.map(workspace => ({
        key: workspace.id,
        text: `${workspace.name} Workspace`,
        onClick: () => {
            setSelectedWorkspace(workspace.id);
            history.push(`/workspace/${workspace.id}`)
        },
    })) ?? [];
    const workspaceEntries: IContextualMenuItem[] = [];
    workspaceEntries.push( {
        key: "sectionTitle",
        text: "Recent Workspaces",
        itemType: ContextualMenuItemType.Header
    });
    workspaceEntries.push(...ws)
    workspaceEntries.push( {
        key: "divider1",
        itemType: ContextualMenuItemType.Divider
    } );
    workspaceEntries.push( {
        disabled: false,
        key: "ac-more-ws",
        text: "More...",
        onClick: () => setWorkspaceSearchModalOpen(true)} );
    workspaceEntries.push( {
        key: "divider2",
        itemType: ContextualMenuItemType.Divider
    } );
    workspaceEntries.push({
        key: 'newWorkspace',
        text: 'Create New',
        iconProps: {iconName: 'Add'},
        onClick: () => setCreateWorkspaceModalOpen(true),
    });
    workspaceEntries.push({
        key: 'deleteWorkspace',
        text: 'Delete',
        iconProps: {iconName: 'Delete'},
        onClick: () => setDeleteWorkspaceModalOpen(true),
    });
    workspaceEntries.push({
        key: 'customizeColumns',
        text: 'Customize Columns',
        iconProps: { iconName: 'edit'},
        onClick: () => setEditColumnsModalOpen(true),
    });
    workspaceEntries.push({
        key: 'import',
        text: 'Import File',
        iconProps: { iconName: 'OpenFile'},
        onClick: props.onImportSelection,
    })
    if (selectedOrganization?.documentAnalyzerEnabled) {
        workspaceEntries.push({
            key: 'customerRequest',
            text: 'Customer Request',
            iconProps: {iconName: 'ChatBot'},
            onClick: props.onCustomerRequestSelection,
        })
    }
    if (canStorefront){
        workspaceEntries.push({
            key: 'storefront',
            text: 'Storefront',
            iconProps: {iconName: 'Shop'},
            onClick: props.onStorefrontSelection,
        })
    }
    workspaceEntries.push({
        key: 'toggleTemplate',
        text: workspaceData?.isTemplate ? "Remove from Templates" : "Mark as Template",
        iconProps: { iconName: 'WebTemplate'},
        onClick: () => toggleTemplate(!(workspaceData?.isTemplate)),
    });
    if (auth.embeddedSession) {
        workspaceEntries.push(
            {
                key: 'remove',
                text: `Remove ${currentSelectedItems.length} ${pluralized(currentSelectedItems.length, 'Product')}`,
                disabled: currentSelectedItems.length === 0,
                iconProps:{ iconName: 'Delete'},
                onClick: removeClick,
            },
            {
                key: 'refreshVendors',
                text: props.refreshingVendors ? 'Refreshing' : 'Refresh Vendors',
                iconProps:{ iconName: 'Sync' },
                disabled: props.refreshingVendors,
                onClick: props.onRefreshVendors,
            },
            {
                key: 'refresh',
                text: 'Refresh',
                iconProps: {iconName: 'Refresh'},
                onClick: props.onRefresh,
            },
        )
    }

    const selectedExtensionMenuItem = ( name : string) => {
        return <div style={{color:"rgb(107, 140, 42)", marginLeft:4}}>{name}</div>
    }
    const availableExtensionMenuItems = (possibleExtensions : IInstalledExtension[]) => {
        return possibleExtensions?.sort((x,y) => {
            return x.id == selectedExtension ? -1 : y.id == selectedExtension ? 1 : 0;
        }).
        map((extension, i) => i == 0 && extension.id == selectedExtension ? ({
                onRenderContent:() => selectedExtensionMenuItem(extension.name),
                key: extension.id,
                text: extension.name,
                onClick: () => props.onExtensionSelection(extension)}) : {
                key: extension.id,
                text: extension.name,
                onClick: () => props.onExtensionSelection(extension)
            }
        );
    }
    const shouldOfferRegeneration = () => {
        return (workspaceData?.documentIsCurrent != true && workspaceData?.mostRecentDocumentExtension);
    }


    const getCommands = () => {
        const commands: ICommandBarItemProps[] = []
        const org = selectedOrganization ?? (getOrganizations?.data?.length === 1 ? getOrganizations.data[0] : undefined)
        const documentExtensions = getInstalled.data?.filter(e => e.enabled == true && e.extensionType === 'document');
        const availableExtensions = getInstalled.data?.filter((e => 
            e.enabled && 
            (e.extensionType === 'export' || e.extensionType === 'recommendation') || e.extensionType === 'directImport'));
        if (auth.embeddedSession) {
            commands.push(
                {
                    key: 'extensions',
                    text: 'Extensions',
                    subMenuProps: {
                        items: availableExtensionMenuItems(availableExtensions ?? []) ?? [],
                    }
                },
                {
                    key: 'workspace',
                    text: 'Workspace',
                    subMenuProps: {
                        items: workspaceEntries,
                    }
                },
            )
        } else {
            commands.push({
                key: 'remove',
                text: currentSelectedItems
                    ? `Remove ${currentSelectedItems.length} ${pluralized(currentSelectedItems.length, 'product')}`
                    : 'No products selected',
                disabled: currentSelectedItems.length === 0,
                iconProps:{ iconName: 'Delete'},
                onClick: removeClick
            })
            commands.push({
                key: 'refreshVendors',
                text: props.refreshingVendors ? 'Refreshing' : 'Refresh Vendors',
                iconProps:{ iconName: 'Sync' },
                disabled: props.refreshingVendors,
                onClick: props.onRefreshVendors
            })
            commands.push({
                key: 'contact',
                text: `${!props.selectedContact? 'Attach Contact' : 'Edit Contact'}`,
                iconProps:{ iconName: 'ContactLink'},
                disabled: loadingWorkspace,
                onClick: openCompanyModal 
            })
            commands.push({
                key: 'adhoc',
                iconOnly:true,
                iconProps:{ iconName: 'Add'},
                tooltipHostProps:{content:"Create Ad-Hoc Workspace Item", calloutProps:{gapSpace: 0}, styles: {root:{ display: 'inline-block' }}},
                onClick: props.onAdhocClick
            })
            if(workspaceData?.documentUrl){
                commands.push({
                    key: 'viewDocument',
                    text: 'View Document',
                    onClick: openDocument
                })
            }
            if(org?.procurementEnabled){
                commands.push({
                    disabled: !props.selectedContact,
                    key: 'procurement',
                    text: `Procure`,
                    iconProps:{ iconName: 'DeliveryTruck'},
                    onClick: openProcurement
                })
            }
            if (documentExtensions && documentExtensions.length > 0) {
                const items: IContextualMenuItem[] = [{
                    key: "sectionTitle",
                    text: "Document Extensions",
                    itemType: ContextualMenuItemType.Header
                }, ...documentExtensions.map((ext) => (
                    {
                        key: ext.id, 
                        text: ext.name,
                        onClick: (a: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined, b: IContextualMenuItem | undefined) => {
                            setDocumentGenerating(true)
                            runDocumentExtension({ extensionId: b?.key ?? "", workspaceId: workspaceData?.id ?? ""})
                                .then(() => setDocumentGenerating(false))
                        }
                    }))]
                if(shouldOfferRegeneration()) {
                    commands.push({
                        key: 'documents',
                        text: documentGenerating ? 'Document Refreshing' : 'Refresh Document',
                        split: true,
                        onClick: () => {
                            setDocumentGenerating(true)
                            runDocumentExtension({
                                extensionId: workspaceData?.mostRecentDocumentExtension ?? "",
                                workspaceId: workspaceData?.id ?? ""
                            }).then(() => setDocumentGenerating(false))
                        },
                        disabled: documentGenerating,
                        subMenuProps: {
                            items: [...items],
                        },
                        iconProps: {iconName: 'Refresh'}

                    })
                }
                else {
                    commands.push({
                        key: 'documents',
                        text: documentGenerating ? 'Document Refreshing' : 'Documents',
                        subMenuProps: {
                            items: [...items],
                        },
                        disabled: documentGenerating,
                        iconProps: {iconName: 'TextDocument'}
                    })
                }
            }
            if (auth.sessionKey != null) {
                commands.push({
                    key: 'Submit',
                    text: 'Submit',
                    onClick: () => {
                        submitSession();
                    }
                })
            } else if(availableExtensions && availableExtensions.length > 0) {
                commands.push({
                    key: 'extensions',
                    text: 'Extensions',
                    subMenuProps: {
                        items: availableExtensionMenuItems(availableExtensions) ?? [],
                    }
                });
            }
            commands.push(
                {
                    key: 'workspace',
                    text:`${workspaceData?.name == undefined ? `Loading` : workspaceData.name} Workspace`,
                    subMenuProps: {
                        items: workspaceEntries,
                    }
                },
                {
                    key: 'refresh',
                    text: 'Refresh',
                    iconProps: {iconName: 'Refresh'},
                    onClick: props.onRefresh,
                    iconOnly: true,
                }
            )
        }

        return commands
    }
    
    const workspaceAddFinish = () => {
        if (workspaceToImport) {
            addWorkspaceFromTemplate({name: newWorkspaceName, template: workspaceToImport.toString()})
                .then((result: any) => {
                    if (result?.data?.id) {
                        if(workspaceToImport) {
                            importProducts({sourceId: workspaceToImport.toString(), destinationId: result.data.id})
                        }
                        setSelectedWorkspace(result.data.id)
                    }
                    setCreateWorkspaceModalOpen(false);
                })
        }
        else {
            addWorkspace(newWorkspaceName).then((result: any) => {
                if (result?.data?.id) {
                    setSelectedWorkspace(result.data.id)
                }
                setCreateWorkspaceModalOpen(false);
            })
        }

    }
    const getWorkspaceTemplateEntries = () => {
        const blank = {key:`noTemplate`, text: ``}
        const values = getWorkspaceTemplates.data ? 
            getWorkspaceTemplates.data.map(a => ({key: a.id, text: a.name})) : []
        
        values.splice(0, 0, blank)
        return values;
    }
    return (
        <>
            <form ref={formRef} id="cxml_form" method="POST" action={submitUrl} encType="application/x-www-form-urlencoded">
                <input type="hidden" name="cXML-urlencoded" value={submitData} />
            </form>
            <CommandBar items={[]} farItems={getCommands()}/>
            <Dialog
                hidden={!createWorkspaceModalOpen}
                onDismiss={() => setCreateWorkspaceModalOpen(false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Create New Workspace',
                }}
                modalProps={{
                    isBlocking: true,
                }}
            >
                <TextField label='Workspace Name'
                           onChange={(e, v) => setNewWorkspaceName(v ?? '')}
                           onKeyDown={(e) => {if(e.key === "Enter") workspaceAddFinish()}}
                />
                <br/>
                {getWorkspaceTemplates.data && <Dropdown
                    dropdownWidth={0}
                    options={getWorkspaceTemplateEntries()}
                    selectedKey={workspaceToImport}
                    label={"Workspace Item Template (Optional)"}
                    onChange={(e, a) => {
                        if (a?.key != 'noTemplate') {
                            setWorkspaceToImport(a?.key)
                        }
                    }}
                />}
                <br/>
                <DialogFooter>
                    <DefaultButton
                        primary
                        disabled={newWorkspaceName == undefined || newWorkspaceName == ``}
                        text='Create'
                        onClick={workspaceAddFinish}
                    />
                    <DefaultButton
                        text='Cancel'
                        onClick={() => setCreateWorkspaceModalOpen(false)}
                    />
                </DialogFooter>
            </Dialog>
            <WorkspaceSearchModal
                displayModal={workspaceSearchModalOpen}
                closer={() =>{ setWorkspaceSearchModalOpen(false)}}
            />
            
            {selectedWorkspace && <WorkspaceProcurementModal 
                selectedWorkspace={selectedWorkspace} 
                displayModal={procurementModalOpen}
                closer={() => {setProcurementModalOpen(false)}}
                selectedItems={currentSelectedItems && currentSelectedItems.length > 0 ? currentSelectedItems: workspaceData?.products}
            />}
            
            {selectedWorkspace && <WorkspaceContactModal
                selectedContact={selectedContact}
                displayModal={contactModalOpen}
                closer={() =>{ setContactModalOpen(false)}}
                onChange={(companyName, contactId, companyId) => {updateContact(companyName, contactId, companyId)}}
            />}
            
            <WorkspaceDeleteModal
                displayModal={deleteWorkspaceModalOpen}
                selectedWorkspace={selectedWorkspace}
                onDeleteWorkspace={() => {
                    if(selectedWorkspace && selectedWorkspace != "" && selectedWorkspace == punchoutWorkspaceId){
                        setPunchoutWorkspace(undefined);
                    }
                    const data = recentWorkspaces;
                    if (data === undefined) return;
                    const selectedWs = data[0].id == selectedWorkspace ? data[1] : data[0]
                    setSelectedWorkspace(selectedWs.id)

                }}
                closer={() => setDeleteWorkspaceModalOpen(false)}
            />
            <CustomizationModal
                page='workspace'
                isOpen={editColumnsModalOpen}
                onClose={() => setEditColumnsModalOpen(false)}
            />
        </>
    )
}

export default WorkspaceControls;