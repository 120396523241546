import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    ISearchRequest
} from '../models/Search';
import {cloneDeep} from 'lodash';
import {ICatalog} from "../models/Catalog";

export interface ISearchSliceState {
    criteria: ISearchRequest;
    selectedCatalog?: ICatalog;
    searchId?: string;
    desiredQuantity?: number;
    sortBestPrice?: 'ASC' | 'DESC';
    bestPriceExclusion?: string[];
}

export const defaultCriteria: ISearchRequest = {
    pageSize: 50,
    inStock: false,
    manufacturer: [],
    vendor: [],
    must: [],
    exclude: [],
    boost: [],
    checkRealtimeSelection: false,
    filters: [],
}

const initialState: ISearchSliceState = {
    criteria: defaultCriteria,
    desiredQuantity: undefined,
}

const searchSlice = createSlice({
    name: 'searchSlice',
    initialState: cloneDeep(initialState),
    reducers: {
        updateSearchCriteria: (state, action: PayloadAction<ISearchRequest>) => {
            state.criteria = action.payload;
            state.sortBestPrice = undefined;
        },
        updateSelectedCatalog: (state, action: PayloadAction<ICatalog | undefined>) => {
            state.selectedCatalog = action.payload;
        },
        updateSearchId: (state, action: PayloadAction<string>) => {
            state.searchId = action.payload;
        },
        updateDesiredQuantity: (state, action: PayloadAction<number | undefined>) => {
            state.desiredQuantity = action.payload;
        },
        updateSortBestPrice: (state, action: PayloadAction<'ASC' | 'DESC' | undefined>) => {
            state.sortBestPrice = action.payload;
        },
        updateBestPriceExclusion: (state, action: PayloadAction<string[]>) => {
            state.bestPriceExclusion = action.payload;
        }
    }
});

export const {
    updateSearchCriteria,
    updateSelectedCatalog,
    updateSearchId,
    updateDesiredQuantity,
    updateSortBestPrice,
    updateBestPriceExclusion,
} = searchSlice.actions;

export default searchSlice.reducer;