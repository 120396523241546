import {
    IAttribute,
    IFilledFilters,
    IFilter,
    IFilterMetadata,
    IMetadataAttribute,
    IMetadataAttributeCalculated,
    IMetadataAttributeOptions,
    ISavedSearch,
    ISearchRequest
} from "../models/Search";

export function SavedSearchToSearchRequest(search: ISavedSearch): ISearchRequest {
    return {
        keywords: search.keywords,
        page: search.page,
        pageSize: search.pageSize,
        inStock: search.inStock,
        lowPrice: search.lowPrice,
        highPrice: search.highPrice,
        manufacturer: search.manufacturer,
        vendor: search.vendor,
        must: search.must,
        boost: search.boost,
        exclude: search.exclude,
        category: search.category,
        segment: search.segment,
        checkRealtimeSelection: search.checkRealtimeSelection,
        filters: search.filters,
    };
}

export function CreateSearchQueryString(criteria: ISearchRequest, selectedCatalog?: string): string {
    let url = "/search?";
    if (selectedCatalog) {
        url += `&selectedCatalog=${encodeURIComponent(selectedCatalog)}`;
    }
    if (criteria.inStock) {
        url += "&inStock=true";
    }
    if (criteria.highPrice) {
        url += `&highPrice=${criteria.highPrice}`;
    }
    if (criteria.lowPrice) {
        url += `&lowPrice=${criteria.lowPrice}`;
    }
    if (criteria.page) {
        url += `&page=${criteria.page}`;
    }
    if (criteria.keywords) {
        url += `&keywords=${encodeURIComponent(criteria.keywords)}`;
    }
    if (criteria.must) {
        url += `&must=${enocodeAttributes(criteria.must)}`;
    }
    if (criteria.exclude) {
        url += `&exclude=${enocodeAttributes(criteria.exclude)}`;
    }
    if (criteria.boost) {
        url += `&boost=${enocodeAttributes(criteria.boost)}`;
    }
    if (criteria.segment) {
        url += `&segment=${encodeURIComponent(criteria.segment)}`;
    }
    if (criteria.category) {
        url += `&category=${encodeURIComponent(criteria.category)}`;
    }
    return url;
}

export function LoadSearchQueryString(criteria: ISearchRequest, params: URLSearchParams) {
    const inStock = params.get("inStock");
    if (inStock && inStock === "true") {
      criteria.inStock = true;
    }

    const highPrice = params.get("highPrice");
    if (highPrice) {
      criteria.highPrice = parseFloat(highPrice);
    }

    const lowPrice = params.get("lowPrice");
    if (lowPrice) {
      criteria.lowPrice = parseFloat(lowPrice);
    }

    const keywords = params.get("keywords");
    if (keywords) {
      criteria.keywords = keywords;
    }

    const must = params.get("must");
    if (must) {
      criteria.must = decodeAttributes(must);
    }

    const exclude = params.get("exclude");
    if (exclude) {
      criteria.exclude = decodeAttributes(exclude);
    }

    const boost = params.get("boost");
    if (boost) {
      criteria.boost = decodeAttributes(boost);
    }

    const segment = params.get("segment");
    if (segment) {
      criteria.segment = segment;
    }

    const category = params.get("category");
    if (category) {
      criteria.category = category;
    }
    return criteria;
}

function enocodeAttributes(attributes: IAttribute[]) {
    let result = "";
    attributes.forEach((attribute) => {
        const raw = `${attribute.name}|${attribute.type}|${attribute.value}`;
        const encoded = encodeURIComponent(raw);
        result += (result ? "," : "") + encoded;
    });
    return result;
}

function decodeAttributes(encoded: string): IAttribute[] {
    return encoded
        .split(",")
        .map((attribute) => {
            const parts = attribute
                .split("|");
            return {
                name: parts[0],
                type: parts[1],
                value: parts[2],
            };
        });
}

export const mergeAttributes = (metadata: IMetadataAttribute, criteria: IAttribute[], attributeType: string) => {
    criteria.forEach((attribute) => {
        if (attribute.type === attributeType) {
            const values = metadata[attribute.name];
            if (values) {
                if (!values.includes(attribute.value)) {
                    values.push(attribute.value);
                    metadata[attribute.name] = values;
                }
            } else {
                metadata[attribute.name] = [attribute.value];
            }
        }
    });
    return metadata;
}

export const calculateAttribute = (metadata: IMetadataAttribute, must: IAttribute[], boost: IAttribute[], exclude: IAttribute[]): IMetadataAttributeCalculated => {
    const calculated: IMetadataAttributeCalculated = {};
    for (const attr of Object.entries(metadata)) {
        const options: IMetadataAttributeOptions = {};
        for (const option of attr[1]) {
            if (must.findIndex(m => m.name === attr[0] && m.value === option) !== -1) {
                options[option] = 'MUST'
            } else if (boost.findIndex(b => b.name === attr[0] && b.value === option) !== -1) {
                options[option] = 'BOOST';
            } else if (exclude.findIndex(e => e.name === attr[0] && e.value === option) !== -1) {
                options[option] = 'EXCLUDE'
            } else {
                options[option] = 'NONE';
            }
        }
        calculated[attr[0]] = options;
    }
    return calculated;
}

export const criteriaIsBlank = (criteria: ISearchRequest) => {
    return (
        (criteria.keywords == null || criteria.keywords === '') &&
        (criteria.category == null || criteria.category === '') &&
        (criteria.segment == null || criteria.segment === '') &&
        (criteria.manufacturer == null || criteria.manufacturer.length === 0) &&
        (criteria.tags == null || criteria.tags.length === 0) &&
        (criteria.userTags == null || criteria.userTags.length === 0) &&
        (criteria.productLines == null || criteria.productLines.length === 0) &&
        (criteria.inStorefront !== true) &&
        (criteria.storefrontFilter == null || criteria.storefrontFilter.length === 0)
    );
}

export const createFilter = (filterMetadata: IFilterMetadata): IFilter[] => {
    const processedFilters: IFilter[] = [];
    for (const filter of Object.entries(filterMetadata)) {
        const newFilter: IFilter = {name: filter[0], filterMin: filter[1].min, filterMax: filter[1].max};
        processedFilters.push(newFilter);
    }
    return processedFilters;
}

export const mergeFilters = (metadata: IFilter[], criteria: IFilledFilters) => {
    for (const [key,value] of Object.entries(criteria)) {
        const index = metadata.findIndex((f) => f.name === key);
        if(index === -1) metadata.push({name: key, filterMin: value.min, filterMax: value.max});
    }
    return metadata;
}
