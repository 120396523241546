import React from 'react';
import { IGlobalStorefrontSettings, IStorefrontSettings } from '../../models/Storefront';
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';
import StorefrontSettingsForm from './StorefrontSettingsForm';
import { NEW_STOREFRONT } from './NewStorefrontPanel';
import StorefrontPreview from './StorefrontPreview';
import { useSaveStorefrontMutation } from '../../store/Api';

interface IStorefrontDetailsPanelProps {
    storefront?: IStorefrontSettings;
    globalSettings?: IGlobalStorefrontSettings;
    isOpen: boolean;
    onClose: () => void;
}

const StorefrontDetailsPanel: React.FC<IStorefrontDetailsPanelProps> = (props) => {
    const [storefront, setStorefront] = React.useState<IStorefrontSettings>(props.storefront ?? NEW_STOREFRONT);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [updateStorefront] = useSaveStorefrontMutation();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    React.useEffect(() => {
        setStorefront((existing) => props.storefront ?? NEW_STOREFRONT);
    }, [props.storefront])

    const save = () => {
        if (props.storefront?.id == null) return;
        setSaving(true);
        setErrorMessage(undefined)
        updateStorefront({storefront, id: props.storefront.id}).unwrap().then((e) => {

            setSaving(false);
            if(e.success) {
                props.onClose();
            }
            else{
                setErrorMessage(e.message ?? "an error occurred saving storefront");
            }
        });
    }

    const footer = () => (
        <div style={{background: "white"}}>
            <Stack horizontal tokens={{childrenGap: '1em'}}>
                <PrimaryButton text='Save' onClick={save} disabled={saving} />
                <DefaultButton text='Cancel' onClick={props.onClose} disabled={saving} />
            </Stack>
        </div>
    )

    return (
        <Panel
            type={PanelType.large}
            headerText={props.storefront?.name}
            isOpen={props.isOpen}
            onDismiss={props.onClose}
            closeButtonAriaLabel='Close'
            isFooterAtBottom={true}
            onRenderFooterContent={footer}
        >
            <Stack tokens={{childrenGap: '1em'}} horizontal>
                <div
                    style={{
                        width: '50%',
                        padding: '1em',
                        borderRight: '1px solid #f3f2f1',
                    }}
                >
                    <StorefrontSettingsForm
                        globalSettings={props.globalSettings}
                        settings={storefront}
                        onChange={(updated) => setStorefront((existing) => ({...updated}))}
                        errorMessage={errorMessage}
                        />
                </div>
                <StorefrontPreview globalSettings={props.globalSettings} storefrontSettings={storefront} />
            </Stack>
        </Panel>
    );
};

export default StorefrontDetailsPanel;