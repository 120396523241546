import React, {ReactElement, useEffect, useState} from "react";
import {INormalizedProduct} from "../../models/NormalizedProduct";
import {VendorsTable} from "./VendorsTable";
import {NotesDetails} from "./NotesDetails";
import {AttributesTable, IAttributeKV} from "./AttributesTable";
import BasicDetails from "./BasicDetails";
import AddToWorkspaceButton from "../ProductDetails/AddToWorkspaceButton";
import BasicDetailsTable from "./BasicDetailsTable";
import {DefaultButton, Panel, PanelType, Pivot, PivotItem, Stack, Text} from "@fluentui/react";
import LinkedItemsDetails from "./LinkedItemsDetails";
import {
    usePutProductMutation,
    useDeleteProductImagesMutation,
    usePutTagsMutation,
    usePutUserTagsMutation
} from "../../store/Api";
import Header from "../Common/Header";
import {cloneDeep} from "lodash";
import {ProductImages} from "./ProductImages";
import EditableImage from "./EditableImage";
import {IProductBase, IProductImage, IProductStorefrontEntry} from "../../models/Search";
import ProductTagsAutoSuggest from "../Search/ProductTagsAutoSuggest"
import SearchFilter from "../Common/SearchFilter";
import {ProductLinesTable} from "./ProductLinesTable";
import ProductStorefrontDetails from "./ProductStorefrontDetails";
import CAN, { STOREFRONT_ADMIN } from "../../permissions/ability";

interface IDetailsModalProps {
    product: INormalizedProduct;
    open: boolean;
    onClose: () => void;
    showWorkspaceButton?: boolean;
    trigger?: ReactElement;
    fluid?: boolean;
    grid?: boolean;
    refreshingVendors?: boolean;
    preventEdit?: boolean;
}

const DetailsModal: React.FC<IDetailsModalProps> = (props) => {
    const [saving, setSaving] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [deleteCustomImageFiles] = useDeleteProductImagesMutation();
    const [saveProduct] = usePutProductMutation();
    const [updateUserTags] = usePutUserTagsMutation();
    const [updateTags] = usePutTagsMutation();
    const [updatedProduct, setUpdatedProduct] = useState<INormalizedProduct | undefined>()
    const [attrArray, setAttrArray] = useState<IAttributeKV[]>([]);
    const [productImages, setProductImages] = useState<IProductImage[]>([])
    const [facetArray, setFacetArray] = useState<IAttributeKV[]>([]);
    const [filterArray, setFilterArray] = useState<IAttributeKV[]>([]);
    const [tags, setTags] = useState<string[]>()
    const [userTags, setUserTags] = useState<string[]>()
    const [productLines, setProductLines] = useState<string[]>([])
    const [updatedProductImageUrl, setProductImageUrl] = useState<string>();
    const [productImagesToDelete, setProductImagesToDelete] = useState<IProductImage[]>([]);
    const [storefrontOverrideMarkup, setStorefrontOverrideMarkup] = useState<number | undefined>();
    const [storefrontOverrideMarkupType, setStorefrontOverrideMarkupType] = useState<string | undefined>()
    const [storefronts, setStorefronts] = useState<{[key:string]: IProductStorefrontEntry} | undefined>();
    const [includedStorefronts, setIncludedStorefronts] = useState<string[]>();
    const [featuredStorefronts, setFeaturedStorefronts] = useState<string[]>();
    const canStorefrontAdmin = CAN(STOREFRONT_ADMIN);
    useEffect(() => {
        const attributes = props.product.attributes != null
            ? Object.entries(props.product.attributes).map((a) => ({key: a[0], value: a[1]}))
            : [];
        const facets = props.product.facets != null
            ? Object.entries(props.product.facets).map((a) => ({key: a[0], value: a[1]}))
            : [];
        const filters = props.product.filters
            ? Object.entries(props.product.filters).map((a) => ({key: a[0], value: a[1]}))
            : [];
        const images = props.product.images ?? [];
        const productLines = props.product.productLines ?? [];
        setProductImageUrl(props.product.image);
        setAttrArray(attributes);
        setFacetArray(facets);
        setFilterArray(filters);
        setProductImages(images);
        setTags(props.product.tags);
        setUserTags(props.product.userTags);
        setProductLines(productLines);
    }, [
        props.product.attributes,
        props.product.facets,
        props.product.filters,
        props.product.images,
        props.product.image,
        props.product.tags,
        props.product.userTags,
        props.product.productLines,
    ]);

    useEffect(() => {
        setStorefrontOverrideMarkup(props.product.storefrontOverrideMarkup);
        setStorefrontOverrideMarkupType(props.product.storefrontOverrideMarkupType);
        setStorefronts(props.product.storefronts);
        setIncludedStorefronts(props.product.includedStorefronts);
        setFeaturedStorefronts(props.product.featuredStorefronts);
    }, [
        props.product.storefrontOverrideMarkup,
        props.product.storefrontOverrideMarkupType,
        props.product.storefronts,
    ]);

    const enableEditMode = () => {
        if (!props.preventEdit) {
            setUpdatedProduct(props.product);
            setEditMode(true)
        }
    }

    const initialState = cloneDeep(props.product)
    const addProductImageToDeleteQueue = (image: IProductImage) => {
        const currentImagesToDelete = cloneDeep(productImagesToDelete)
        currentImagesToDelete.push(image)
        setProductImagesToDelete(currentImagesToDelete);
    }

    const mapUpdatedDetails = () => {
        if (updatedProduct) {
            const prod = cloneDeep(updatedProduct)
            prod.storefrontOverrideMarkup = storefrontOverrideMarkup;
            prod.storefrontOverrideMarkupType = storefrontOverrideMarkupType;
            prod.storefronts = storefronts;
            prod.includedStorefronts = includedStorefronts;
            prod.featuredStorefronts = featuredStorefronts;
            if (updatedProductImageUrl) {
                prod.image = updatedProductImageUrl
            }
            if (attrArray) {
                prod.attributes = Object.assign({}, ...attrArray.map((x) => ({[x.key]: x.value})));
            }
            if (productImages) {
                prod.images = productImages
            }
            if (facetArray) {
                prod.facets = Object.assign({}, ...facetArray.map((x) => ({[x.key]: x.value})));
            }
            if (filterArray) {
                prod.filters = Object.assign({}, ...filterArray.map((x) => ({[x.key]: x.value})));
            }
            if (productLines) {
                prod.productLines = productLines;
            }
            return prod;
        }
        return undefined;
    }
    const save = () => {
        const newProduct = mapUpdatedDetails();
        if (newProduct) {
            setSaving(true);
            if (productImagesToDelete && productImagesToDelete.length > 0) {
                deleteCustomImageFiles({imageIds: productImagesToDelete.map(a => a.id), productId: newProduct.id}).
                then(() => {
                    saveProduct(newProduct).then(() => {
                    setSaving(false);
                    setUpdatedProduct(undefined);
                    props.onClose();
                });});
            }
            else {
                saveProduct(newProduct).then(() => {
                    setSaving(false);
                    setUpdatedProduct(undefined);
                    props.onClose();
                });
            }
        }
        setEditMode(false);
    }

    const updateProductImageUrl = (id: string, url: string) => {
        setProductImageUrl(url)
    }
    const productName = props.product?.name ?? 'Product Details';
    const mfp = props.product?.manufacturerPartNumber ? `${props.product.manufacturerPartNumber}` : '';
    const productImage: IProductImage = {
        name: "Product Image",
        url: updatedProductImageUrl ?? props.product.image ?? '',
        type: "Product",
        id: props.product.id,
        isCustom: true
    };

    const removeUserTag = (tag: string) => {
        const tagCopy = cloneDeep(userTags) ?? [];
        const index = tagCopy.findIndex(a => a == tag);
        tagCopy.splice(index, 1)
        setUserTags(tagCopy);
        updateUserTags({productId: props.product.id, catalog: props.product.catalog, tags: tagCopy});
    }
    const removeTag = (tag: string) => {
        const tagCopy = cloneDeep(tags) ?? [];
        const index = tagCopy.findIndex(a => a == tag);
        tagCopy.splice(index, 1)
        setTags(tagCopy);
        updateTags({productId: props.product.id, catalog: props.product.catalog, tags: tagCopy});
    }
    const addUserTag = (tag: string) => {
        const userTagCopy = cloneDeep(userTags) ?? [];
        tag = tag.replace("||", "")
        if (userTagCopy.indexOf(tag) === -1) {
            userTagCopy.push(tag);
            setUserTags(userTagCopy);
            updateUserTags({productId: props.product.id, catalog: props.product.catalog, tags: userTagCopy});
        }
    }
    const addTag = (tag: string) => {
        const tagCopy = cloneDeep(tags) ?? [];
        tag = tag.replace("||", "")
        if (tagCopy.indexOf(tag) === -1) {
            tagCopy.push(tag);
            setTags(tagCopy);
            updateTags({productId: props.product.id, catalog: props.product.catalog, tags: tagCopy});
        }
    }

    const setProductFromBase = (product: IProductBase) => {
        setUpdatedProduct({
            ...product,
            id: props.product.id,
            catalog: props.product.catalog,
        });
    }

    return (
        <div>
            <Panel
                className={"detail-panel"}
                onOuterClick={() => {console.log("swallowing modal close event")}}
                isOpen={props.open}
                onDismiss={() => {
                    props.onClose()
                }}
                type={PanelType.large}
                isFooterAtBottom={true}
                styles={{
                    commands: {
                        background: "white"
                    },
                    footer: {
                        background: "white"
                    },
                    navigation: {
                        flex: '1 1 auto',
                    },
                }}
                onRenderHeader={() => {
                    return (
                        <div style={{flexGrow: 1, paddingLeft: '25px', background: "white"}}>
                            <Stack horizontal disableShrink>
                                <Stack.Item align='auto'>
                                    <EditableImage image={productImage} editMode={editMode}
                                                   onChange={(a, b) => setProductImageUrl(b)}/>
                                </Stack.Item>
                                <Stack.Item align='center' style={{paddingLeft: '1em'}}>
                                    <Text variant={'xLarge'} block>
                                        {productName}
                                    </Text>
                                    <Text variant={'small'} block>
                                        Manufacturer Part Number: {mfp}
                                    </Text>
                                </Stack.Item>
                            </Stack>
                        </div>
                    )
                }}
                onRenderFooterContent={() => (
                    <div style={{background: "white"}}>
                        {editMode
                            ? <><DefaultButton
                                text={saving ? "Saving" : "Save"}
                                iconProps={{iconName: 'Save'}}
                                disabled={saving}
                                primary
                                onClick={() => {
                                    save();
                                }}
                            />
                                <DefaultButton
                                    text={"Cancel"}
                                    iconProps={{iconName: 'cancel'}}
                                    disabled={saving}
                                    onClick={() => {
                                        setEditMode(false);
                                        setUpdatedProduct(initialState);
                                        setProductImages(initialState.images)
                                        setProductImageUrl(initialState.image)
                                        setTags(initialState.tags)
                                        setUserTags(initialState.userTags)
                                    }}
                                    style={{marginLeft: '1em'}}
                                />
                            </>
                            : <DefaultButton
                                text="Edit"
                                iconProps={{iconName: 'Edit'}}
                                primary
                                disabled={props.preventEdit ?? false}
                                onClick={() => {
                                    if (props.product) {
                                        enableEditMode();
                                    }
                                }}
                            />
                        }
                        {props.product && !editMode
                            ? <AddToWorkspaceButton onChange={() => props.onClose()} product={props.product}/>
                            : null
                        }
                    </div>
                )}
            >
                {props.product
                    ? (
                        <>
                            <div>
                                <Pivot overflowBehavior='menu' styles={{itemContainer: {marginTop: '1em'}}}>
                                    <PivotItem headerText='Details'>
                                        <BasicDetails
                                            editMode={editMode}
                                            product={editMode && updatedProduct ? updatedProduct : props.product}
                                            id={props.product.id}
                                            onChange={setProductFromBase}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText='Attributes'>
                                        <>
                                            <BasicDetailsTable
                                                product={editMode && updatedProduct ? updatedProduct : props.product}
                                                editMode={editMode}
                                                onChange={setProductFromBase}
                                                catalog={props.product.catalog}
                                            />
                                            <AttributesTable
                                                title='Attributes'
                                                attributes={attrArray}
                                                onUpdate={(a) => setAttrArray(a)}
                                                editMode={editMode}
                                            />
                                            <AttributesTable
                                                title='Facets'
                                                attributes={facetArray}
                                                onUpdate={(a) => setFacetArray(a)}
                                                editMode={editMode}
                                            />
                                            <AttributesTable
                                                title='Filters'
                                                attributes={filterArray}
                                                onUpdate={(a) => setFilterArray(a)}
                                                editMode={editMode}
                                            />
                                            <ProductLinesTable
                                                title='Product Lines'
                                                productLines={productLines}
                                                editMode={editMode}
                                                onUpdate={(a) => setProductLines(a)}>
                                            </ProductLinesTable>
                                        </>
                                    </PivotItem>
                                    {/* <PivotItem headerText='Extensions'>
                                </PivotItem> */}
                                    <PivotItem headerText='Tags'>
                                        <h4>Global Tags</h4>
                                        <ProductTagsAutoSuggest onCreate={(tag) => addTag(tag)}
                                                                editable={!props.preventEdit}></ProductTagsAutoSuggest>
                                        {tags && tags.length > 0 ?
                                            tags?.map((tag) =>
                                                <SearchFilter key={tag} color={"#9BD3AB"} protected={props.preventEdit}
                                                              onDelete={() => removeTag(tag)}>{tag}</SearchFilter>
                                            ) : !props.preventEdit ? <></> : <div>No Global Tags</div>}
                                        <h4>My Tags</h4>
                                        <ProductTagsAutoSuggest onCreate={(tag) => addUserTag(tag)}
                                                                editable={true}></ProductTagsAutoSuggest>
                                        {userTags && userTags.length > 0 ?
                                            userTags?.map((tag) =>
                                                <SearchFilter key={tag} color={"#F9D585"} protected={false}
                                                              onDelete={() => removeUserTag(tag)}>
                                                    {tag}</SearchFilter>
                                            ) : <></>}
                                    </PivotItem>
                                    <PivotItem headerText='Vendor'>
                                        <VendorsTable
                                            vendors={props.product.vendors ?? []}
                                            product={props.product}
                                            refreshing={props.refreshingVendors}
                                        />
                                    </PivotItem>
                                    {/* <PivotItem headerText='Insights'>
                                </PivotItem> */}
                                    <PivotItem headerText='Linked Products'>
                                        {(props.product.links && props.product.links.length > 0) ?
                                            <LinkedItemsDetails product={props.product} editMode={editMode}/> :
                                            <Header icon={"FabricFolderFill"}>
                                                No Linked Items Found
                                            </Header>}
                                    </PivotItem>
                                    <PivotItem headerText='Media'>
                                        <ProductImages images={productImages} editMode={editMode}
                                                       title={"Product Images"}
                                                       onUpdate={(a) => setProductImages(a)}
                                                       onSetExternalProduct={(ext) => updateProductImageUrl(ext.id, ext.url)}
                                                       productId={props.product.id}
                                                       onDelete={addProductImageToDeleteQueue}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText='Notes'>
                                        <NotesDetails product={{catalog: props.product.catalog, id: props.product.id}}/>
                                    </PivotItem>
                                    {canStorefrontAdmin && <PivotItem headerText='Storefront'>
                                        <ProductStorefrontDetails
                                            editMode={editMode}
                                            storefrontOverrideMarkup={storefrontOverrideMarkup}
                                            storefrontOverrideMarkupType={storefrontOverrideMarkupType}
                                            storefronts={storefronts}
                                            onStorefrontsChange={setStorefronts}
                                            includedStorefronts={includedStorefronts}
                                            onIncludedStorefrontsChange={setIncludedStorefronts}
                                            featuredStorefronts={featuredStorefronts}
                                            onFeaturedStorefrontsChange={setFeaturedStorefronts}
                                            onStorefrontOverrideMarkupChange={setStorefrontOverrideMarkup}
                                            onStorefrontOverrideMarkupTypeChange={setStorefrontOverrideMarkupType}
                                            />
                                    </PivotItem>}
                                </Pivot>
                            </div>
                        </>
                    )
                    : null
                }
            </Panel>
        </div>
    )
}

export default DetailsModal;