export interface IGlobalStorefrontSettings {
    name?: string;
    logo?: string;
    title?: string;
    bannerText?: string;
    theme?: string;
    menuBarColor?: string;
    menuBarTextColor?: string;
    backgroundColor?: string;
    textColor?: string;
    headerFont?: string;
    defaultFont?: string;
    termsAndConditions?: string;
    orderEmail?: string;
    customerEmailText?: string;
    customerEmailSubject?: string;
    storefrontsEnabled?: boolean;
    checkoutEnabled?: boolean;
    checkoutType?: string;
    defaultMarkup?: number;
    defaultMarkupType?: string;
    defaultStorefrontSubUrl?: string;
    currency: string;
    opportunityExtension?: string;
    paymentProcessor?: string;
    collectTax?: boolean;
    enableOpportunityCreation?: boolean;
    orderFailureEmail?: string;
    storefrontVendors?: string[];
    requireNotes?: boolean;
    notesLabel?: string;
    notesInstructions?: string;
}

export interface IStorefrontAdminSessionResponse {
    redirectUrl?: string;
}

export interface IStorefrontSettings {
    disableNotes?: boolean;
    notesLabel?: string;
    id?: string;
    name?: string;
    logo?: string;
    title?: string;
    theme?: string;
    bannerText?: string;
    menuBarColor?: string;
    menuBarTextColor?: string;
    backgroundColor?: string;
    textColor?: string;
    headerFont?: string;
    defaultFont?: string;
    termsAndConditions?: string;
    overrideTermsAndConditions?: boolean;
    orderEmail?: string;
    customerEmailText?: string;
    customerEmailSubject?: string;
    checkoutEnabled?: boolean;
    checkoutType?: string;
    defaultMarkup?: number;
    defaultMarkupType?: string;
    fullCatalogs?: string[];
    subUrl: string;
    enabled?: boolean;
    currency?: string;
    paymentProcessor?: string;
    collectTax?: boolean;
    opportunitySettings?: IStorefrontOpportunitySetting[];
    disableOpportunityCreation?: boolean;
    storefrontVendors?: string[];
    notesInstructions?: string;
}
export interface IStorefrontOpportunitySetting {
    name?: string;
    value?: string
}

export interface IStorefrontOrder {
    id: string;
    storefront: string;
    emailAddress: string;
    ipAddress: string;
    orderNumber: number;
    status: string;
    createDate: Date;
    total: number;
    shippingAddress: IStorefrontOrderAddress;
    billingAddress: IStorefrontOrderAddress;
    products: IStorefrontOrderProduct[];
    bundles: IStorefrontOrderBundle[];
}

export interface IStorefrontOrderProduct {
    productId: string;
    quantity: number;
    price?: number;
    name?: string;
    manufacturer?: string;
}

export interface IStorefrontOrderBundle {
    id: string;
    quantity: number;
    price?: number;
    cost?: number;
    name?: string;
    image?: string;
    products: IStorefrontOrderBundleProduct[];
}

export interface IStorefrontOrderBundleProduct {
    productId: string;
    quantity: number;
    name?: string;
    image?: string;
    cost: IStorefrontCost;
}

export interface IStorefrontCost {
    cost: number;
    distributor?: string;
}

export interface IStorefrontOrderAddress {
    name?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
    phoneNumber?: string;
}

export interface IStorefrontTheme {
    name: string;
    menuBarColor?: string;
    menuBarTextColor?: string;
    backgroundColor?: string;
    textColor?: string;
}

export interface IStorefrontMonthlyOrders {
    year: number;
    month: number;
    count: number;
    total: number;
}

export interface IPaymentProcessorSetupDetails {
    stripeSetup?: boolean;
}

export interface IStripeSetupRequest {
    key: string;
}

export const PAYMENT_PROCESSOR_KEYS = {
    NONE: 'NONE',
    STRIPE: 'STRIPE',
}

export const PAYMENT_PROCESSOR_DISPLAY = {
    'NONE': 'None',
    'STRIPE': 'Stripe',
}

export interface IStorefrontOrderStatuses {
    [key: string]: string;
}