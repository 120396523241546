import {
    CommandBar,
    IColumn,
    ICommandBarItemProps,
    mergeStyleSets,
    Panel,
    PanelType,
    PrimaryButton,
    SearchBox,
    SelectionMode, Shimmer,
    ShimmeredDetailsList,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import React, {useEffect, useState} from "react";
import {
    useGetContactsQuery,
    useGetExtensionCompanyQuery,
    useGetInstalledExtensionsQuery,
    useGetContactByIdQuery
} from "../../store/Api";
import {ICompany, IContact} from "../../models/Company";
import {cloneDeep} from "lodash";
import {ButtonColumnGenerator, ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import {EXTENSION_TYPE_CUSTOMERIMPORT, IInstalledExtension} from "../../models/Extension";
import ExtensionCompanyFieldSearch from "./ExtensionCompanyFieldSearch";
import WorkspaceCompanyDetails from "./WorkspaceCompanyDetails";

interface IWorkspaceContactModalProps {
    selectedContact?: string;
    displayModal: boolean;
    closer: () => void;
    onChange: (companyName: string, contactId: string, companyId: string) => void;
}

const WorkspaceContactModal: React.FC<IWorkspaceContactModalProps> = props => {
    const [currentContact, setCurrentContact] = useState<IContact | undefined>(undefined);
    const [selectedExtension, setSelectedExtension] = useState<IInstalledExtension>()
    const [selectedContact, setSelectedContact] = useState<string | undefined>()
    const [extensionCompanyIdentifier, setExtensionCompanyIdentifier] = useState<string | undefined>()
    const [searchText, setSearchText] = useState<string>("")
    const [value] = useState<string | undefined>()
    const [canEdit, setCanEdit] = useState<boolean>();
    const [isAttachedFromExtension, setIsAttachedFromExtension] = useState<boolean>();
    const [availableExtensions, setAvailableExtensions] = useState<IInstalledExtension[]>()
    const {data: getSelectedContact, isLoading: isContactLoading, refetch: refetchContact} = useGetContactByIdQuery({contactId: selectedContact ?? ""})
    const {data: extensionCompanyDetailsData, isLoading: extensionCompanyDetailsLoading} = useGetExtensionCompanyQuery(
        {extensionId: selectedExtension?.id ?? "", companyIdentifier: extensionCompanyIdentifier ?? ""}, {
            skip: !extensionCompanyIdentifier || selectedExtension?.id == undefined || selectedExtension?.id == ''})
    const [extensionCompany, setExtensionCompany ] = useState<ICompany | undefined>();
    const {data: searchData, isLoading: searchIsLoading} = useGetContactsQuery({query: {query: searchText, pageSize: 100}, includeCompanyDetails: true},
        {skip: searchText == undefined || searchText == ''});
    const getExtensions = useGetInstalledExtensionsQuery();

    useEffect(() => {
        if(getExtensions?.data) {
            setAvailableExtensions(
                getExtensions.data?.filter(e => e.extensionType === EXTENSION_TYPE_CUSTOMERIMPORT)
            )
        }
    }, [getExtensions.data])

    useEffect(() => {
        if(props.selectedContact){
            setSelectedContact(props.selectedContact)
        }
        else{
            setSelectedContact(undefined)
            setCurrentContact(undefined)
        }
    }, [props.selectedContact, selectedContact])

    useEffect(() => {
        if(selectedContact && getSelectedContact && !isContactLoading){
            setCurrentContact(getSelectedContact)
        }
    }, [selectedContact, getSelectedContact, isContactLoading])

    useEffect(() => {
        if(extensionCompanyDetailsData) {
            const contact: IContact = {
                id: extensionCompanyDetailsData.contact?.id ?? '',
                emailAddress: extensionCompanyDetailsData.contact?.emailAddress ?? '',
                firstName: extensionCompanyDetailsData.contact?.firstName ?? '',
                lastName: extensionCompanyDetailsData.contact?.lastName ?? '',
                phoneNumber: extensionCompanyDetailsData.contact?.phoneNumber ?? '',
                externalId: extensionCompanyDetailsData.contact?.externalId ?? ''
            }
            setExtensionCompany(extensionCompanyDetailsData)
            setCurrentContact(contact)
            setIsAttachedFromExtension(true)
        }
    }, [extensionCompanyDetailsData, extensionCompanyDetailsLoading, extensionCompanyIdentifier])
    
    const clear = () => {
        setExtensionCompanyIdentifier(undefined)
        setSelectedExtension(undefined)
        setSelectedContact(undefined)
        setCurrentContact(undefined)
        //Change this order cautiously
        props.onChange('', '', '')
        setIsAttachedFromExtension(false)
        setCanEdit(false)
        setSearchText('')
    }
    
    const contentStyles = mergeStyleSets({
        root: {
            zIndex: '10 !important',
            marginTop: '48px',
        },
        main: {
            marginTop: '48px',
        },
        scrollableContent: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        navigation: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        footerInner: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        content: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        commands: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
    });

    const selectedExtensionMenuItem = ( name : string) => {
        return <div style={{color:"rgb(107, 140, 42)", marginLeft:4}}>{name}</div>
    }
    const availableExtensionMenuItems = (possibleExtensions : IInstalledExtension[]) => {
        return possibleExtensions?.
        map((extension) => ({
            onRenderContent:() => selectedExtensionMenuItem(extension.name),
            key: extension.id,
            text: extension.name,
            onClick: () => setSelectedExtension(extension)})
        );
    }
    
    const importCommand: ICommandBarItemProps = {
        key: 'extensions',
        text: 'Extensions',
        subMenuProps: {
            items: availableExtensions ? availableExtensionMenuItems(availableExtensions) : [],
        }
    }
    const removeCommand: ICommandBarItemProps = {
        key: 'clear',
        text: 'Remove Contact',
        iconProps: { iconName: 'Delete' },
        onClick: () => {clear()},
        disabled: canEdit }
    const backCommand: ICommandBarItemProps = {
        key: 'clear',
        text: 'Back To Search',
        iconProps: { iconName: 'Back' },
        onClick: () => {clear()},
        disabled: canEdit }
    const farCommands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {refetchContact()}, iconOnly: true
        }]
    const cancelExtensionCommand: ICommandBarItemProps = {
        key: 'cancel',
        text: 'Cancel Extension Search',
        iconProps: { iconName: 'Cancel' },
        onClick: () => {setSelectedExtension(undefined)},
    }
    
    const columns: IColumn[] = [
        ColumnGenerator('First Name','firstName', 100),
        ColumnGenerator('Last Name','lastName', 100),
        ColumnGenerator('Company','companyName', 100),
        ColumnGenerator('Identifier','companyIdentifier', 100, ),
        ButtonColumnGenerator((c: IContact) => {updateContactDetails(c)}, '', 'Select')
    ]

    const updateContactDetails = (updatedContact: IContact) => {
        setCurrentContact(updatedContact);
    }
    
    const getAttachedCommands = () => {
        if(selectedContact){
            return([removeCommand])
        }
        else if(currentContact){
            return([backCommand])
        }
        return ([])
    }

    const getExtensionCommands = () => {
        return([cancelExtensionCommand])
    }

    const getSearchCommands = () => {
        if(availableExtensions && availableExtensions.length > 0) {
            return [importCommand]
        }
        return []
    }
    
    const updateAndClose = () => {
        if(currentContact) {
            const contact = cloneDeep(currentContact)
            setCurrentContact(contact)
            props.onChange(contact?.companyName ?? "", contact.id ?? "", contact?.company ?? "")
            close()
        }
        else {
            close()
        }
    }

    const close = () => {
        setCanEdit(false)
        setSelectedExtension(undefined)
        setExtensionCompanyIdentifier(undefined)
        setIsAttachedFromExtension(false)
        setSearchText('')
        props.closer()
    }
    
    const saveButton = () => {
        if(currentContact && currentContact.id !== selectedContact) {
            return (
                <PrimaryButton
                    style={{width: '100%', height: '40px'}}
                    disabled={!currentContact}
                    onClick={() => {
                        updateAndClose()
                    }}
                    text={"Save"}
                />
            )
        }
        else return <div></div>
    }

    const getCurrentScreen = () => {
        if(!currentContact && !selectedExtension) {
            return(                        <div>
                <CommandBar items={getSearchCommands()} farItems={farCommands}/>
                <SearchBox
                    defaultValue={searchText ?? undefined}
                    placeholder={"Search"}
                    value={value}
                    onChange={(e, d) => {setSearchText(d ?? '');
                    }}
                />
                <br/>
                <ShimmeredDetailsList
                    items={searchText ? searchData?.items ?? [] : []}
                    enableShimmer={searchIsLoading}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                />
            </div>)
        }
        else if(!isAttachedFromExtension && selectedExtension) {
            return(
                <div>
                    <CommandBar items={getExtensionCommands()} farItems={farCommands}/>
                    <ExtensionCompanyFieldSearch
                        isDisabled={extensionCompanyDetailsLoading}
                        extensionId={selectedExtension.id}
                        onChange={(companyIdentifier) => {
                            setExtensionCompanyIdentifier(companyIdentifier)}
                        }/>
                    <br/>
                    {extensionCompanyDetailsLoading && <Spinner size={SpinnerSize.large}/>}
                </div>)
        }
        else if(!extensionCompanyDetailsLoading){
            return(<div>
                <CommandBar items={getAttachedCommands()} farItems={farCommands}/>
                <WorkspaceCompanyDetails
                    currentContact={cloneDeep(currentContact)}
                    currentCompany={extensionCompany}
                />
            </div>)
        }
        else {
            return(<></>)
        }

    }
    
    
    return (
        <div>
            <Panel
                onOuterClick={() => {console.log("swallowing modal close event")}}
                isOpen={props.displayModal}
                onDismiss={close}
                className={"workspace-company-panel"}
                isFooterAtBottom={true}
                type={PanelType.custom}
                customWidth={"800px"}
                title={"Contact"}
                styles={{
                    root: {
                        zIndex: 10,
                        marginTop: '48px'
                    },
                    content:contentStyles.content,
                    commands:contentStyles.commands,
                    scrollableContent: contentStyles.scrollableContent,
                    navigation: contentStyles.navigation,
                    footerInner: contentStyles.footerInner,
                }}
                onRenderFooter={saveButton}
            >
                <h1>Workspace Contact</h1>
                <Shimmer isDataLoaded={!isContactLoading && !extensionCompanyDetailsLoading}>
                    {getCurrentScreen()}
                </Shimmer>
            </Panel>
        </div>

    );
}
export default WorkspaceContactModal;