import React from "react";
import {
    IDetailsHeaderProps,
    IRenderFunction,
    Selection,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import {IWorkspaceItem} from "../../models/Workspace";
import {
    useGetColumnsQuery,
    useGetWorkspaceProductsQuery,
    usePutWorkspaceItemDetailsMutation,
    useRemoveProductFromWorkspaceMutation
} from "../../store/Api";
import {renderWorkspaceColumns} from "../../logic/Columns/ProductColumns";
import {WORKSPACECOLUMNS} from "../../Constants";
import Header from "../Common/Header";
import Sticky from "react-stickynode"

interface IWorkspaceTableProps {
    workspace: string;
    selectedItems: IWorkspaceItem[];
    onSelectedItemsChanged: (items: IWorkspaceItem[]) => void;
    onDetailsClick: (item:IWorkspaceItem) => void;
    onAdhocClick: (item:IWorkspaceItem | undefined) => void;
}

const WorkspaceTable: React.FC<IWorkspaceTableProps> = (props) => {
    const products = useGetWorkspaceProductsQuery(props.workspace);
    const [removeProduct] = useRemoveProductFromWorkspaceMutation();
    const [detailsChange] = usePutWorkspaceItemDetailsMutation();
    const getColumns = useGetColumnsQuery();
    const displayProducts = products.data;
    
    if (props.workspace == null || (!products.isLoading && (products.data == null || products.data.length === 0))) {
        return (
            <Header icon={"FabricFolderFill"}>
                No Products on Workspace
            </Header>
        )
    }

    const selection = new Selection({
        onSelectionChanged: () => {
            const selected = selection.getSelection() as IWorkspaceItem[];
            props.onSelectedItemsChanged(selected);
        },
    })

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        return (<Sticky enabled={true} top={48} innerZ={10}>{defaultRender!({...props})}</Sticky>);
    };

    const columns = renderWorkspaceColumns(
        getColumns.data?.workspaceColumns ?? WORKSPACECOLUMNS,
        false,
        false,
        (product, details) => {
            detailsChange({workspace: props.workspace, details, product: product.id})
        },
        (item) => {
            removeProduct({workspaceId: props.workspace, wsItemId: item});
        },
        props.onDetailsClick,
        props.onAdhocClick
    );

    return (
        <>
            <ShimmeredDetailsList
                enableShimmer={products.isFetching}
                items={displayProducts ?? []}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                onRenderDetailsHeader={onRenderDetailsHeader}
            />
        </>
    )
};

export default WorkspaceTable;