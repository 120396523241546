import React from 'react'
import { ICompany } from '../../models/Company'
import { DefaultButton, Panel, PanelType, Pivot, PivotItem, PrimaryButton, Stack, TextField } from '@fluentui/react';
import StorefrontDropdown from '../Storefront/StorefrontDropdown';
import { usePutCompanyMutation } from '../../store/Api';
import CompanyContactsDisplay from './CompanyContactsDisplay';

interface ICompanyDetailsModalProps {
    company?: ICompany;
    isOpen: boolean;
    onClose: () => void;
}

const CompanyDetailsModal: React.FC<ICompanyDetailsModalProps> = (props) => {
    const [saveCompany] = usePutCompanyMutation();
    const [companyName, setCompanyName] = React.useState<string>(props.company?.companyName ?? '');
    const [companyIdentifier, setCompanyIdentifier] = React.useState<string>(props.company?.companyIdentifier ?? '');
    const [contactFirstName, setContactFirstName] = React.useState<string | undefined>(props.company?.contact?.firstName);
    const [contactLastName, setContactLastName] = React.useState<string | undefined>(props.company?.contact?.lastName);
    const [contactEmail, setContactEmail] = React.useState<string | undefined>(props.company?.contact?.emailAddress);
    const [contactPhone, setContactPhone] = React.useState<string | undefined>(props.company?.contact?.phoneNumber);
    const [addressAddress1, setAddressAddress1] = React.useState<string | undefined>(props.company?.address?.address1);
    const [addressAddress2, setAddressAddress2] = React.useState<string | undefined>(props.company?.address?.address2);
    const [addressAddress3, setAddressAddress3] = React.useState<string | undefined>(props.company?.address?.address3);
    const [addressCity, setAddressCity] = React.useState<string | undefined>(props.company?.address?.city);
    const [addressState, setAddressState] = React.useState<string | undefined>(props.company?.address?.stateTerritory);
    const [addressCountry, setAddressCountry] = React.useState<string | undefined>(props.company?.address?.country);
    const [addressPostalCode, setAddressPostalCode] = React.useState<string | undefined>(props.company?.address?.postalCodeZip);
    const [website, setWebsite] = React.useState<string | undefined>(props.company?.website);
    const [storefront, setStorefront] = React.useState<string | undefined>(props.company?.storefront);
    const [primaryContact, setPrimaryContact] = React.useState<string | undefined>(props.company?.primaryContact);

    React.useEffect(() => {
        setCompanyName(props.company?.companyName ?? '');
        setCompanyIdentifier(props.company?.companyIdentifier ?? '');
        setStorefront(props.company?.storefront);
        setContactFirstName(props.company?.contact?.firstName);
        setContactLastName(props.company?.contact?.lastName);
        setContactEmail(props.company?.contact?.emailAddress);
        setContactPhone(props.company?.contact?.phoneNumber);
        setAddressAddress1(props.company?.address?.address1);
        setAddressAddress2(props.company?.address?.address2);
        setAddressAddress3(props.company?.address?.address3);
        setAddressCity(props.company?.address?.city);
        setAddressState(props.company?.address?.stateTerritory);
        setAddressCountry(props.company?.address?.country);
        setAddressPostalCode(props.company?.address?.postalCodeZip);
        setWebsite(props.company?.website);
    }, [props.company])

    const save = () => {
        saveCompany({
            id: props.company?.id,
            companyIdentifier,
            companyName,
            storefront,
            contact: {
                firstName: contactFirstName,
                lastName: contactLastName,
                emailAddress: contactEmail,
                phoneNumber: contactPhone
            },
            address: {
                address1: addressAddress1,
                address2: addressAddress2,
                address3: addressAddress3,
                city: addressCity,
                stateTerritory: addressState,
                country: addressCountry,
                postalCodeZip: addressPostalCode
            },
            website
        }).then(() => {
            props.onClose();
        });
    }

    const footer = () => (
        <Stack horizontal tokens={{childrenGap: '1em'}}>
            <PrimaryButton onClick={save}>Save</PrimaryButton>
            <DefaultButton onClick={props.onClose}>Close</DefaultButton>
        </Stack>
    );

    return (
        <Panel
            type={PanelType.medium}
            isOpen={props.isOpen}
            onDismiss={props.onClose}
            headerText={props.company?.companyName}
            closeButtonAriaLabel='Close'
            isFooterAtBottom={true}
            onRenderFooterContent={footer}
        >
            <Pivot styles={{itemContainer:{paddingTop:'1em'}}}>
                <PivotItem headerText='Details'>
                    <TextField label='Company Name' value={companyName} onChange={(e, v) => setCompanyName(v ?? '')} />
                    <TextField label='Company Identifier' value={companyIdentifier} onChange={(e, v) => setCompanyIdentifier(v ?? '')} />
                    <TextField label='Create Date' value={props.company?.createDate?.toString()} disabled readOnly />
                    <TextField label='Last Updated' value={props.company?.lastAccessed?.toString()} disabled readOnly />
                    <StorefrontDropdown useId selected={storefront} onChange={setStorefront} />
                    <TextField label='Website' value={website} onChange={(e, v) => setWebsite(v ?? '')} />
                    <h3>Address</h3>
                    <TextField label='Address 1' value={addressAddress1} onChange={(e, v) => setAddressAddress1(v ?? '')} />
                    <TextField label='Address 2' value={addressAddress2} onChange={(e, v) => setAddressAddress2(v ?? '')} />
                    <TextField label='Address 3' value={addressAddress3} onChange={(e, v) => setAddressAddress3(v ?? '')} />
                    <TextField label='City' value={addressCity} onChange={(e, v) => setAddressCity(v ?? '')} />
                    <TextField label='State' value={addressState} onChange={(e, v) => setAddressState(v ?? '')} />
                    <TextField label='Country' value={addressCountry} onChange={(e, v) => setAddressCountry(v ?? '')} />
                    <TextField label='Postal Code' value={addressPostalCode} onChange={(e, v) => setAddressPostalCode(v ?? '')} />
                    <h3>Contact</h3>
                    <TextField label='First Name' value={contactFirstName} onChange={(e, v) => setContactFirstName(v ?? '')} />
                    <TextField label='Last Name' value={contactLastName} onChange={(e, v) => setContactLastName(v ?? '')} />
                    <TextField label='Email' value={contactEmail} onChange={(e, v) => setContactEmail(v ?? '')} />
                    <TextField label='Phone' value={contactPhone} onChange={(e, v) => setContactPhone(v ?? '')} />
                    <TextField label='Primary Contact' value={primaryContact} onChange={(e, v) => setPrimaryContact(v ?? '')} />
                </PivotItem>
                <PivotItem headerText='Contacts'>
                    {props.company?.id && <CompanyContactsDisplay companyId={props.company.id} />}
                </PivotItem>
            </Pivot>
        </Panel>
    )
}

export default CompanyDetailsModal