import {IAuthSliceState} from "../store/authSlice";

export class BaseDAL {
    protected baseUrl = window.config.baseUrl;
    private readonly _token?: string;
    private readonly _tokenType: 'Bearer' | 'SessionKey';
    protected readonly _organization?: string;
    private readonly _apiVersion?: number;
    constructor(
        auth: IAuthSliceState,
        organization?: string
    ) {
        this._token = auth.sessionKey ?? auth.accessToken;
        this._tokenType = auth.sessionKey ? 'SessionKey' : 'Bearer';
        this._organization = organization;
        this._apiVersion = window.config.apiVersion
    }

    protected async get<T>(url: string, signal?: AbortSignal): Promise<T | null> {
        return this
            ._get<T>(url, signal)
            .catch((error) => {
                if (error.name === 'AbortError') return null;
                if (error.response && error.response.status === 401) {
                    // return this._authContext.userManager.signinSilent().then((renewedUser) => {
                    //     return this._get<T>(url, renewedUser.access_token);
                    // });
                }
                throw error;
            });
    }

    protected async post<T>(url: string, body?: string, signal?: AbortSignal): Promise<T | null> {
        if (this._organization) {
            url += `&organization=${this._organization}`;
        }
        return this
            ._post<T>(url, body, signal)
            .catch((error) => {
                if (error.name === 'AbortError') return null;
                if (error.response && error.response.status === 401) {
                    // return this._authContext.userManager.signinSilent().then((renewedUser) => {
                    //     return this._get<T>(url, renewedUser.access_token);
                    // });
                }
                throw error;
            });
    }

    protected async put<T>(url: string, body?: string, signal?: AbortSignal): Promise<T | null> {
        if (this._organization) {
            url += `&organization=${this._organization}`;
        }
        return this
            ._put<T>(url, body, signal)
            .catch((error) => {
                if (error.name === 'AbortError') return null;
                if (error.response && error.response.status === 401) {
                    // return this._authContext.userManager.signinSilent().then((renewedUser) => {
                    //     return this._get<T>(url, renewedUser.access_token);
                    // });
                }
                throw error;
            });
    }

    protected async delete<T>(url: string, signal?: AbortSignal): Promise<T | null> {
        if (this._organization) {
            url += `&organization=${this._organization}`;
        }
        return this
            ._delete<T>(url, signal)
            .catch((error) => {
                if (error.name === 'AbortError') return null;
                if (error.response && error.response.status === 401) {
                    // return this._authContext.userManager.signinSilent().then((renewedUser) => {
                    //     return this._get<T>(url, renewedUser.access_token);
                    // });
                }
                throw error;
            });
    }

    protected _generateHeaders(): Headers {
        if(!this._token) {
            throw new Error('Missing Authentication');
        }
        if (this._tokenType === 'SessionKey') {
            const sHeaders = new Headers({
                'x-session-key': this._token,
                'Content-Type': 'application/json',
            })
            if(this._apiVersion) {
                sHeaders.append('x-api-version', this._apiVersion.toString())
            }
            if (this._organization)
                sHeaders.append('x-organization', this._organization);
            return sHeaders;
        } else {
            const bHeaders = new Headers({
                Authorization: `Bearer ${this._token}`,
                'Content-Type': 'application/json',
            })
            if(this._apiVersion) {
                bHeaders.append('x-api-version', this._apiVersion.toString())
            }
            if (this._organization)
                bHeaders.append('x-organization', this._organization);
            return bHeaders;
        }
        
    }

    protected async _get<T>(url: string, signal?: AbortSignal): Promise<T> {
        const response = await fetch(url, {
            headers: this._generateHeaders(),
            signal: signal,
        });
        if (response.status === 401) {
            // eslint-disable-next-line
            throw {
                response: response,
            };
        }
        const data = await response.json();
        return data as T;
    }

    private async _post<T>(url: string, body?: string, signal?: AbortSignal): Promise<T> {
        const response = await fetch(url, {
            headers: this._generateHeaders(),
            method: "POST",
            body: body,
            signal: signal,
        });
        if (response.status === 401) {
            // eslint-disable-next-line
            throw {
                response: response,
            };
        }
        const data = await response.json();
        return data as T;
    }

    private async _put<T>(url: string, body?: string, signal?: AbortSignal): Promise<T> {
        const response = await fetch(url, {
            headers: this._generateHeaders(),
            method: "PUT",
            body: body,
            signal: signal,
        });
        if (response.status === 401) {
            // eslint-disable-next-line
            throw {
                response: response,
            };
        }
        const data = await response.json();
        return data as T;
    }

    private async _delete<T>(url: string, signal?: AbortSignal): Promise<T> {
        const response = await fetch(url, {
            headers: this._generateHeaders(),
            method: "DELETE",
            signal: signal,
        });
        if (response.status === 401) {
            // eslint-disable-next-line
            throw {
                response: response,
            };
        }
        const data = await response.json();
        return data as T;
    }
}

export interface IActionResponse {
    success: boolean;
    message: string;
    id: string;
}

export interface IUploadFileResponse {
    success?: boolean;
    error?: string;
    url: string;
}
