import React from 'react';
import { IStorefrontBundlePricing, IWorkspace } from '../../models/Workspace';
import { Checkbox, Panel, PrimaryButton, TextField } from '@fluentui/react';
import NumberField from '../Inputs/NumberField';
import { useGetStorefrontsQuery, usePutWorkspaceMutation } from '../../store/Api';
import MarkupTypeSelection from '../Storefront/MarkupTypeSelection';

interface IStorefrontBundleModalProps {
    isOpen: boolean;
    onDismiss: () => void;
    workspace?: IWorkspace;
}

const StorefrontBundleModal: React.FC<IStorefrontBundleModalProps> = (props) => {
    const [bundleName, setBundleName] = React.useState<string>('');
    const [bundleDescription, setBundleDescription] = React.useState<string>('');
    const [bundleImageUrl, setBundleImageUrl] = React.useState<string>('');
    const [bundleMinimumPrice, setBundleMinimumPrice] = React.useState<number>();
    const [selectedStorefronts, setSelectedStorefronts] = React.useState<string[]>([]);
    const [pricing, setPricing] = React.useState<IStorefrontBundlePricing[]>([]);
    const storefronts = useGetStorefrontsQuery();
    const [saveWorkspace] = usePutWorkspaceMutation();

    React.useEffect(() => {
        if (props.workspace == null) return;
        setBundleName(props.workspace.storefrontBundleDetails?.name ?? '');
        setBundleDescription(props.workspace.storefrontBundleDetails?.description ?? '');
        setBundleImageUrl(props.workspace.storefrontBundleDetails?.imageUrl ?? '');
        setBundleMinimumPrice(props.workspace.storefrontBundleDetails?.minimumPrice);
        setSelectedStorefronts(props.workspace.storefrontBundleDetails?.storefronts ?? []);
        setPricing(props.workspace.storefrontBundleDetails?.pricing ?? []);
    }, [props.workspace]);

    const save = async () => {
        // Save the storefront bundle
        if (props.workspace == null) return;
        await saveWorkspace({
            id: props.workspace.id,
            storefrontBundleDetails: {
                name: bundleName,
                description: bundleDescription,
                imageUrl: bundleImageUrl,
                minimumPrice: bundleMinimumPrice,
                storefronts: selectedStorefronts,
                pricing: pricing
            }
        });
        props.onDismiss();
    }

    return (
        <Panel
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            headerText='Storefront Bundle'
            isFooterAtBottom={true}
            onRenderFooter={() => (
            <PrimaryButton
                text='Save'
                onClick={save}
                />
            )}
        >
            <div>
                <h4>Details</h4>
                <TextField
                    label='Name'
                    value={bundleName}
                    onChange={(e, newValue) => setBundleName(newValue ?? '')}
                    placeholder={props.workspace?.name}
                    />
                <TextField
                    multiline={true}
                    rows={10}
                    label='Description'
                    value={bundleDescription}
                    onChange={(e, newValue) => setBundleDescription(newValue ?? '')}
                    />
                <NumberField
                    label='Minimum Price'
                    value={bundleMinimumPrice}
                    onChange={setBundleMinimumPrice}
                    prefix='$'
                    />
                <h4>Storefronts</h4>
                {storefronts.data?.items?.map((storefront) => (
                    <div key={storefront.id} style={{marginBottom: '1em'}}>
                        <Checkbox
                            styles={{label: { fontWeight: 'bold' }}}
                            label={storefront.name}
                            checked={selectedStorefronts.includes(storefront.id ?? '')}
                            onChange={(e, checked) => {
                                if (checked) {
                                    setSelectedStorefronts([...selectedStorefronts, storefront.id ?? '']);
                                } else {
                                    setSelectedStorefronts(selectedStorefronts.filter((id) => id !== storefront.id));
                                }
                            }}
                            />
                            {selectedStorefronts.includes(storefront.id ?? '') && <>
                                <NumberField
                                    label='Markup'
                                    value={pricing.find((p) => p.storefront === storefront.id)?.markup}
                                    onChange={(markup) => {
                                        const newPricing = pricing.filter((p) => p.storefront !== storefront.id);
                                        if (markup != null) {
                                            newPricing.push({ storefront: storefront.id ?? '', markup });
                                        }
                                        setPricing(newPricing);
                                    }}
                                    />
                                <MarkupTypeSelection
                                    label='Markup Type'
                                    value={pricing.find((p) => p.storefront === storefront.id)?.markupType}
                                    onChange={(markupType) => {
                                        const newPricing = pricing.filter((p) => p.storefront !== storefront.id);
                                        if (markupType != null) {
                                            newPricing.push({ storefront: storefront.id ?? '', markupType });
                                        }
                                        setPricing(newPricing);
                                    }}
                                    />
                            </>}
                    </div>
                ))}
            </div>
        </Panel>
    );
}

export default StorefrontBundleModal;