import React from 'react';
import { useGetGlobalStorefrontSettingsQuery, useSaveGlobalStorefrontSettingsMutation } from '../../store/Api';
import GlobalStorefrontSettingsForm from '../../components/Storefront/GlobalStorefrontSettingsForm';
import {Checkbox, Pivot, PivotItem, PrimaryButton, Stack, StackItem} from '@fluentui/react';
import StorefrontPreview from '../../components/Storefront/StorefrontPreview';
import StorefrontsTable from '../../components/Storefront/StorefrontsTable';
import {IGlobalStorefrontSettings, IStorefrontSettings} from '../../models/Storefront';
import NewStorefrontPanel from '../../components/Storefront/NewStorefrontPanel';
import StorefrontDetailsPanel from '../../components/Storefront/StorefrontDetailsPanel';
import StorefrontOrdersTable from '../../components/Storefront/Orders/StorefrontOrdersTable';
import StorefrontMonthlyOrdersTable from '../../components/Storefront/StorefrontMonthlyOrdersTable';
import StorefrontProductCount from '../../components/Storefront/StorefrontProductCount';
import PaymentProcessorDetails from '../../components/Storefront/PaymentProcessors/PaymentProcessorDetails';
import StorefrontOpportunityExtensionSelection
    from "../../components/Storefront/StorefrontOpportunityExtensionSelection";
import GlobalStorefrontOpportunityExtensionForm from "../../components/Storefront/GlobalStorefrontOpportunityExtensionForm";

const StorefrontManagementPage = () => {
    const globalSettings = useGetGlobalStorefrontSettingsQuery();
    const [tempGlobalSettings, setTempGlobalSettings] = React.useState(globalSettings.data);
    const [saveGlobalSettings] = useSaveGlobalStorefrontSettingsMutation();
    const [selectedStorefront, setSelectedStorefront] = React.useState<IStorefrontSettings | undefined>();
    const [newStorefront, setNewStorefront] = React.useState<boolean>(false);
    const [extension, setExtension] = React.useState<string | undefined>();
    const [enableOpportunityCreation, setEnableOpportunityCreation] = React.useState<boolean>(false);
    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    React.useEffect(() => {
        setTempGlobalSettings(globalSettings.data);
        if(globalSettings.data?.opportunityExtension) {
            setExtension(globalSettings.data?.opportunityExtension)
        }
        if(globalSettings.data?.enableOpportunityCreation) {
            setEnableOpportunityCreation(globalSettings.data?.enableOpportunityCreation)
        }
    }, [globalSettings.data]);

    const saveSettings = (settings: IGlobalStorefrontSettings) => {
        if (settings == null) return;
        saveGlobalSettings(settings);
    }

    const save = () => {
        if (tempGlobalSettings == null) return;
        setIsSaving(true);
        saveGlobalSettings(tempGlobalSettings).then(() => setIsSaving(false));
    }
    
    const saveOpportunityExtensionSelection = (selection?: string) => {
        setExtension(selection)
        setTempGlobalSettings( prevState => {
            saveSettings({...prevState, currency: prevState?.currency ?? '', opportunityExtension: selection });
            return {...prevState, currency: prevState?.currency ?? '', opportunityExtension: selection }} );
    }

    const saveEnableOpportunityExtensionSelection = (selection: boolean) => {
        setEnableOpportunityCreation(selection)
        setTempGlobalSettings( prevState => {
            saveSettings({...prevState, currency: prevState?.currency ?? '', enableOpportunityCreation: selection });
            return {...prevState, currency: prevState?.currency ?? '', enableOpportunityCreation: selection }} );
    }

    return (
        <div>
            <h1>Storefront Management</h1>
            <Pivot overflowBehavior='menu' styles={{itemContainer: {marginTop: '1em'}}}>
                <PivotItem headerText='Global Settings'>
                    <Stack horizontal tokens={{childrenGap: '1em'}}>
                        <div style={{width: '45%', minWidth: '300px'}}>
                            <Stack tokens={{childrenGap: '1em'}}>
                                <GlobalStorefrontSettingsForm
                                    globalSettings={tempGlobalSettings}
                                    onChange={setTempGlobalSettings}
                                    />
                                <StackItem>
                                    <PrimaryButton
                                        text='Save'
                                        onClick={save}
                                        disabled={isSaving}
                                        />
                                </StackItem>
                            </Stack>
                        </div>
                        <div style={{width: '45%', minWidth: '500px'}}>
                            <StorefrontPreview globalSettings={tempGlobalSettings} />
                        </div>
                    </Stack>
                </PivotItem>
                <PivotItem headerText='Storefronts'>
                    <h2>Storefronts</h2>
                    <StorefrontsTable onDetailsClick={setSelectedStorefront} onNewClick={() => setNewStorefront(true)} />
                    <NewStorefrontPanel
                        isOpen={newStorefront}
                        globalSettings={tempGlobalSettings}
                        onAddStorefront={() => setNewStorefront(false)}
                        onClose={() => setNewStorefront(false)}
                        />
                    <StorefrontDetailsPanel
                        globalSettings={tempGlobalSettings}
                        isOpen={selectedStorefront !== undefined}
                        storefront={selectedStorefront}
                        onClose={() => setSelectedStorefront(undefined)}
                    />
                </PivotItem>
                <PivotItem headerText='Payment Processing'>
                    <h2>Payment Processing</h2>
                    <PaymentProcessorDetails />
                </PivotItem>
                <PivotItem headerText='Opportunities'>
                    <h2>Opportunities</h2>
                    <StorefrontOpportunityExtensionSelection
                        extension={extension}
                        onChange={saveOpportunityExtensionSelection}
                    />
                    <br></br>
                    <Checkbox
                        label="Enable Opportunity Creation"
                        checked={enableOpportunityCreation}
                        onChange={(e, v) => saveEnableOpportunityExtensionSelection(v ?? false)}
                    />
                    <br></br>
                    {enableOpportunityCreation &&

                    <GlobalStorefrontOpportunityExtensionForm
                        subExtensionFolioCompositeId={extension}
                    />
                    }
                </PivotItem>
                <PivotItem headerText='Orders'>
                    <h2>Orders</h2>
                    <StorefrontOrdersTable />
                </PivotItem>
                <PivotItem headerText='Usage'>
                    <h2>Products</h2>
                    <StorefrontProductCount />
                    <h2>Monthly Orders</h2>
                    <StorefrontMonthlyOrdersTable />
                </PivotItem>
            </Pivot>
        </div>
    );
};

export default StorefrontManagementPage;